import {
  CYCLOPS_ORGANIZATIONS_DATA_LOADED,
  CYCLOPS_ORGANIZATIONS_DATA_CLEAR,
  CYCLOPS_LIVESTREAMS_DATA_LOADED,
  CYCLOPS_LIVESTREAMS_DATA_CLEAR,
  CYCLOPS_LIVESTREAM_DETAILS_LOADED,
  CYCLOPS_LIVESTREAM_DETAILS_CLEAR,
  CYCLOPS_PIRACY_STREAMS_LOADED,
  CYCLOPS_PIRACY_STREAMS_CLEAR,
  CYCLOPS_AUTHED_DOMAINS_LOADED,
  CYCLOPS_AUTHED_DOMAINS_CLEAR,
  CYCLOPS_ANALYTICS_DATA_LOADED,
  CYCLOPS_ANALYTICS_DATA_CLEAR,
  CYCLOPS_CDN_CONTSTANTS_LOADED,
  CYCLOPS_CDN_CONTSTANTS_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  organizationLivestreams: null,
  livestreamDetails: null,
  piracyStreams: null,
  livestreamAuthedDomains: null,
  analyticsData: null,
  cdnConstants: null,
  companyOrganizations: null,
};

const cyclops = (state = initialState, action) => {
  switch (action.type) {
    case CYCLOPS_ORGANIZATIONS_DATA_LOADED:
      return {
        ...state,
        companyOrganizations: action.payload.companyOrganizations,
      };

    case CYCLOPS_ORGANIZATIONS_DATA_CLEAR:
      return {
        ...state,
        companyOrganizations: null,
      };

    case CYCLOPS_LIVESTREAMS_DATA_LOADED:
      return {
        ...state,
        organizationLivestreams: action.payload.livestreams,
      };

    case CYCLOPS_LIVESTREAMS_DATA_CLEAR:
      return {
        ...state,
        organizationLivestreams: null,
      };

    case CYCLOPS_LIVESTREAM_DETAILS_LOADED:
      return {
        ...state,
        livestreamDetails: action.payload.livestreamDetails,
      };

    case CYCLOPS_LIVESTREAM_DETAILS_CLEAR:
      return {
        ...state,
        livestreamDetails: null,
      };

    case CYCLOPS_PIRACY_STREAMS_LOADED:
      return {
        ...state,
        piracyStreams: action.payload.piracyStreams,
      };

    case CYCLOPS_PIRACY_STREAMS_CLEAR:
      return {
        ...state,
        piracyStreams: null,
      };

    case CYCLOPS_AUTHED_DOMAINS_LOADED:
      return {
        ...state,
        livestreamAuthedDomains: action.payload.authedDomains,
      };

    case CYCLOPS_AUTHED_DOMAINS_CLEAR:
      return {
        ...state,
        livestreamAuthedDomains: null,
      };

    case CYCLOPS_ANALYTICS_DATA_LOADED:
      return {
        ...state,
        analyticsData: action.payload.analyticsData,
      };

    case CYCLOPS_ANALYTICS_DATA_CLEAR:
      return {
        ...state,
        analyticsData: null,
      };

    case CYCLOPS_CDN_CONTSTANTS_LOADED:
      return {
        ...state,
        cdnConstants: action.payload.cdnConstants,
      };

    case CYCLOPS_CDN_CONTSTANTS_CLEAR:
      return {
        ...state,
        cdnConstants: null,
      };

    default:
      return state;
  }
};

export default cyclops;
