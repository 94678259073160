import {
  PRODUCT_RESULTS_UPLOAD_CONFIG_LOADED,
  PRODUCT_RESULTS_UPLOAD_CONFIG_CLEAR,
} from '../actionTypes';
import * as api from '../apiHelper';
import { initiateRedirectRequest } from '../redirectActions';
import {
  setFetchLoaderLoading,
  setFetchLoaderReset,
  setSubmissionLoaderLoading,
  setSubmissionLoaderReset,
} from '../fetchLoaderActions';
import {
  arriveNotice,
  arriveCustomNotice,
} from '../noticeActions';


// Reducer corresponding actions
function resultsUploadConfigClear() {
  return { type: PRODUCT_RESULTS_UPLOAD_CONFIG_CLEAR };
}

function resultsUploadConfigLoaded(uploadConfig) {
  return {
    type: PRODUCT_RESULTS_UPLOAD_CONFIG_LOADED,
    payload: { uploadConfig },
  };
}

// API
async function getResultsUploadConfigApi(args, token) {
  const response = await api.get('results/bulk', args, token, 'v3');
  return response.json();
}

async function submitResultsUploadApi(body, token, dispatch) {
  const response = await api.mixedFormDataPost('results/bulk', body, token, 'v3', true);

  if (!response.ok) {
    const json = await response.json();

    if (json?.errors) {
      const customError = api.extractErrorMessage(json.errors);
      dispatch(arriveCustomNotice(customError));

      throw customError;
    } else {
      const error = new Error(json.error);
      dispatch(arriveNotice(error.message));

      throw error;
    }
  }

  return response.json();
}

// Public components actions
export function clearResultsUploadConfigRequest() {
  return (dispatch) => {
    dispatch(resultsUploadConfigClear());
  };
}

export function initiateResultsUploadConfigRequest(args) {
  return async (dispatch, getState) => {
    try {
      dispatch(resultsUploadConfigClear());

      const { auth: { token }, fetchLoader: { dataLoading } } = getState();
      if (!dataLoading) dispatch(setFetchLoaderLoading());

      const response = await getResultsUploadConfigApi(args, token);
      dispatch(resultsUploadConfigLoaded(response));
      dispatch(setFetchLoaderReset());
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(setFetchLoaderReset());
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function submitResultsFileUploadRequest(query, rootPath, resetForm) {
  return async (dispatch, getState) => {
    try {
      const { auth: { token }, fetchLoader: { submissionLoading } } = getState();
      if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

      await submitResultsUploadApi(query, token, dispatch);

      dispatch(arriveNotice('Results uploaded successfully!', 'ok'));
      resetForm();
      dispatch(initiateRedirectRequest(null, rootPath, true));
      dispatch(setSubmissionLoaderReset());
    } catch (e) {
      dispatch(setSubmissionLoaderReset());
    }
  };
}
