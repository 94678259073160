import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import _ from 'lodash';

import {
  Box, Text, Layer, Anchor,
} from 'grommet';

import { Spinning } from '@Components/Control';
import { ReviewTablePartial } from '@Components/Partial/Product/Dashboard';
import { isValidUrl } from '@Helpers/util';
import {
  SearchInput,
  SortDropdown,
  FilterDropdown,
} from '../SharedComponents';
import {
  StyledText,
  StyledRaisedContainer,
  StyledIconButton,
} from '../StyledSharedComponents';


export const SearchDetails = ({
  small, details, textColor, weight = 'normal', attributes = false,
}) => {
  const [showModal, setShowModal] = React.useState(false);

  const renderValue = (val, type) => {
    if (isValidUrl(val) && type !== 'list') {
      return (
        <Anchor href={val} target="_blank">
          <Text color={textColor} size="1rem" weight={weight || 'normal'}>{val}</Text>
        </Anchor>
      );
    }

    return (
      <Text color={textColor} size="1rem" weight={weight || 'normal'}>{val}</Text>
    );
  };

  const renderDetails = (label, value, type, unit) => {
    let displayValue = type === 'list' ? value.join(', ') : value;
    let restValues = null;

    if (type === 'list' && value.length >= 3) {
      let firstValues = value.slice(0, 3);
      restValues = value.slice(3);
      displayValue = `${firstValues.join(', ')} + ${restValues.length} more`;

      if (small) {
        firstValues = value.slice(0, 2);
        restValues = value.slice(2);
        displayValue = `${firstValues.join(', ')}, ...`;
      }
    }

    if (unit) {
      displayValue = `${value}${unit}`;
    }

    return (
      <Box
        key={uniqid()}
        direction="row"
        gap="0.25rem"
        width={(attributes && !small) ? '50%' : '100%'}
        justify={attributes ? 'between' : 'start'}
        align="center"
        pad={attributes ? { bottom: '0.5rem' } : '0'}
        border={attributes && {
          size: '1px',
          side: 'bottom',
          color: '#DDE2E4',
          style: 'solid',
        }}
      >
        <Text color={textColor} size="1rem" weight={weight || 'normal'}>{`${label}:`}</Text>
        <Box direction="row" gap="1rem" align="center">
          {renderValue(displayValue, type)}
          {restValues?.length >= 1 && (
            <>
              <StyledIconButton
                plain
                noMargin
                onClick={() => setShowModal(true)}
                textSize="1rem"
                color={textColor}
                bgColor="white"
                label="View all"
              />
              {showModal && (
                <Layer
                  responsive={false}
                  animate={false}
                  onEsc={() => setShowModal(false)}
                  onClickOutside={() => setShowModal(false)}
                >
                  <Box direction="column" pad="1rem" gap="1rem">
                    <Box
                      border={{
                        side: 'bottom', style: 'solid', size: '1px', color: '#D0D0D0',
                      }}
                    >
                      <Text color={textColor} size="1rem" weight={600}>{`${label}:`}</Text>
                    </Box>
                    <Text color={textColor} size="1rem" weight={weight || 'normal'}>{value.join(', ')}</Text>
                  </Box>
                </Layer>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  };

  if (attributes) {
    return (
      <Box direction={small ? 'column' : 'row'} gap={small ? '2.5rem' : '5rem'} justify="between" width="100%">
        {details.map(({
          label, value, type, unit,
        }) => (
          renderDetails(label, value, type, unit)
        ))}
      </Box>
    );
  }

  return (
    <Box direction="column" gap="0.5rem">
      {details.map(({
        label, value, type, unit,
      }) => (
        renderDetails(label, value, type, unit)
      ))}
    </Box>
  );
};

SearchDetails.propTypes = {
  small: PropTypes.bool.isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string.isRequired),
    ]).isRequired,
    type: PropTypes.string.isRequired,
    unit: PropTypes.string,
  })).isRequired,
  textColor: PropTypes.string.isRequired,
  weight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  attributes: PropTypes.bool,
};

const SimpleCardWidget = ({
  textColor,
  label,
  value,
  unit = null,
  boxMargin = '0',
  backgroundColor = 'white',
}) => {
  const displayValue = unit ? `${value}${unit}` : value;

  return (
    <StyledRaisedContainer
      background={backgroundColor}
      height="6.5rem"
      width="13rem"
      justify="center"
      align="start"
      gap="0.75rem"
      pad="1rem"
      margin={boxMargin}
    >
      <StyledText size="1rem" color={textColor} weight={600}>{label}</StyledText>
      <StyledText size="2rem" color={textColor} weight={800}>{displayValue}</StyledText>
    </StyledRaisedContainer>
  );
};

SimpleCardWidget.propTypes = {
  textColor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  unit: PropTypes.string,
  boxMargin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any.isRequired),
  ]),
  backgroundColor: PropTypes.string,
};

const TableControls = ({
  small,
  focusHighlight,
  buttonHighlight,
  selectButtonBg,
  primaryText,
  displayInputValue = null,
  searchQuery,
  handleFilter = () => null,
  handleSort = () => null,
  filterSortMeta = null,
  searchQueryKey = null,
}) => {
  if (filterSortMeta) {
    const useFilterOptions = _.filter(filterSortMeta.filters.options, ((dt) => !dt.validPredicates.includes('i_cont')));

    return (
      <Box direction="row" gap="0.5rem">
        <SearchInput
          key="search"
          small={small}
          focusHighlight={focusHighlight}
          handleSearch={(uid, value, predicate, seperateValues, reset) => (
            handleFilter(uid, value, predicate, seperateValues, reset)
          )}
          initialValue={displayInputValue}
          queryKey={searchQueryKey}
        />
        <FilterDropdown
          key={uniqid()}
          small={small}
          textColor={primaryText}
          focusHighlight={focusHighlight}
          buttonHighlight={buttonHighlight}
          selectButtonBg={selectButtonBg}
          selectedFilters={searchQuery.filters || null}
          handleFilter={(uid, value, predicate, seperateValues, reset) => (
            handleFilter(uid, value, predicate, seperateValues, reset)
          )}
          filterOptions={useFilterOptions}
        />
        <SortDropdown
          textColor={primaryText}
          focusHighlight={focusHighlight}
          selectedSortOption={searchQuery.sort || null}
          handleSort={(sortValue, sortDirection) => handleSort(sortValue, sortDirection)}
          sortOptions={filterSortMeta.sort.name.options}
          orderOptions={filterSortMeta.sort.direction.options}
        />
      </Box>
    );
  }

  return null;
};

TableControls.propTypes = {
  small: PropTypes.bool.isRequired,
  focusHighlight: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  selectButtonBg: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  displayInputValue: PropTypes.string,
  searchQuery: PropTypes.shape({
    paging: PropTypes.shape({
      to: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
    }),
    sort: PropTypes.shape({
      name: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }),
    filters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      predicate: PropTypes.string.isRequired,
    })),
  }).isRequired,
  handleFilter: PropTypes.func,
  handleSort: PropTypes.func,
  filterSortMeta: PropTypes.shape({
    sort: PropTypes.shape({
      name: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
      direction: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
    }).isRequired,
    filters: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        formatted: PropTypes.string.isRequired,
        validValues: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired),
        validPredicates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }).isRequired).isRequired,
    }).isRequired,
  }),
  searchQueryKey: PropTypes.string,
};

export const WidgetRenderer = ({
  small,
  size,
  loading,
  loaderColor,
  authPagesConfig = null,
  backgroundColor = 'white',
  textColor,
  widgetData = null,
  padding = '0',
  initializeReviewedRows,
  updateRowReviewStatus,
  selectedStatuses = null,
  resultsPerPage = null,
  resultsPerPageOptions,
  currentPage = null,
  totalResults = null,
  handleQuery,
  handleFilter = () => null,
  handleSort = () => null,
  searchQuery,
  filterSortMeta,
}) => {
  const valueWidgets = _.filter(widgetData, ((dt) => dt.type === 'value'));
  const reviewWidget = _.find(widgetData, ((dt) => dt.type === 'review-table'));
  const reviewHeaders = small ? _.filter(reviewWidget?.value?.header, ((dt) => dt.display !== 'Review Status')) : reviewWidget?.value?.header;
  const useSearchQueryKey = filterSortMeta?.filters?.options && _.find(filterSortMeta.filters.options, ((dt) => dt.validPredicates.includes('i_cont')));
  const searchInputValue = searchQuery?.filters && _.find(searchQuery.filters, ['name', filterSortMeta?.searchQueryKey ?? useSearchQueryKey?.name]);
  let displayInputValue = '';

  if (searchInputValue && searchInputValue.value) {
    displayInputValue = searchInputValue.value;
  }

  if (loading || !widgetData) {
    return (
      <Box flex justify="center" align="center">
        <Spinning size="large" color={loaderColor} />
      </Box>
    );
  }

  return (
    <Box flex direction="column" gap={padding}>
      {valueWidgets && valueWidgets.length >= 1 && (
        <Box direction="row" gap="2.5rem" pad={{ horizontal: padding }}>
          {valueWidgets.map(({ label, value }) => (
            <SimpleCardWidget
              key={uniqid()}
              small={small}
              backgroundColor={backgroundColor}
              textColor={textColor}
              label={label}
              value={value}
            />
          ))}
        </Box>
      )}
      <Box>
        {reviewWidget && (
          <ReviewTablePartial
            loading={loading}
            reviewData={reviewWidget?.value?.rows}
            reviewDataHeaders={reviewHeaders}
            initializeReviewedRows={initializeReviewedRows}
            updateRowReviewStatus={updateRowReviewStatus}
            selectedStatuses={selectedStatuses}
            handleResultsPerPage={(val) => handleQuery('paging', 'size', val)}
            handlePage={(val) => handleQuery('paging', 'to', val)}
            resultsPerPageOptions={resultsPerPageOptions}
            resultsPerPage={resultsPerPage}
            currentPage={currentPage}
            totalResults={totalResults}
            authPagesConfig={authPagesConfig}
            size={size}
            searchQuery={searchQuery}
            handleFilter={handleFilter}
            customHeader={reviewWidget?.label}
            tableControls={(
              <TableControls
                small={small}
                focusHighlight={authPagesConfig.focusHighlight}
                buttonHighlight={authPagesConfig.buttonHighlight}
                selectButtonBg={authPagesConfig.selectButtonBg}
                primaryText={authPagesConfig.primaryText}
                displayInputValue={displayInputValue}
                searchQuery={searchQuery}
                handleFilter={handleFilter}
                handleSort={handleSort}
                filterSortMeta={filterSortMeta}
                searchQueryKey={filterSortMeta?.searchQueryKey ?? useSearchQueryKey?.name}
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
};

/* eslint-disable camelcase */
WidgetRenderer.propTypes = {
  small: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'mediumlarge', 'large']).isRequired,
  loading: PropTypes.bool.isRequired,
  loaderColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  widgetData: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    unit: PropTypes.string,
    feature: PropTypes.string,
  }).isRequired),
  padding: PropTypes.string,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
  }),
  selectedStatuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    review_status: PropTypes.string.isRequired,
  })),
  resultsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  resultsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  handleQuery: PropTypes.func.isRequired,
  handleFilter: PropTypes.func,
  handleSort: PropTypes.func,
  initializeReviewedRows: PropTypes.func.isRequired,
  updateRowReviewStatus: PropTypes.func.isRequired,
  totalResults: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  searchQuery: PropTypes.shape({
    paging: PropTypes.shape({
      to: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
    }),
    sort: PropTypes.shape({
      name: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }),
    filters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      predicate: PropTypes.string.isRequired,
    })),
  }).isRequired,
  filterSortMeta: PropTypes.shape({
    sort: PropTypes.shape({
      name: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
      direction: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
    }).isRequired,
    filters: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        formatted: PropTypes.string.isRequired,
        validValues: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired),
        validPredicates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }).isRequired).isRequired,
    }).isRequired,
    searchQueryKey: PropTypes.string,
  }),
};
/* eslint-enable camelcase */
