import React from 'react';
import PropTypes from 'prop-types';
import Case from 'case';

import { Box } from 'grommet';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleQuantize } from 'd3-scale';

import { StyledTooltip } from './StyledCyclopsComponents';
import worldTopoMap from '../../../../assets/configs/worldTopoMap.json';

const getCountryISO2 = require('country-iso-3-to-2');


const GeoHeatmap = ({
  data = null, showTooltip = false,
}) => {
  if (!data) return null;

  const [tooltipContent, setTooltipContent] = React.useState('');

  const dataKeys = Object.keys(data);
  const formattedData = dataKeys.map((key) => ({
    countryCode: Case.upper(key), value: data[key],
  }));

  const colorScale = scaleQuantize()
    .domain([0, Math.max(...formattedData.map((d) => d.value))])
    .range(['#AAE3F5', '#70D0EA', '#0C99CC', '#1479C2']);

  return (
    <Box data-tip direction="column" width="100%" data-for="geo-heatmap-tooltip">
      <ComposableMap
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 200,
        }}
      >
        <Geographies geography={worldTopoMap}>
          {({ geographies }) => geographies.map((geo) => {
            const countryData = formattedData.find((d) => d.countryCode === getCountryISO2(geo.id));
            const color = countryData ? colorScale(countryData.value) : '#eee';

            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={color}
                stroke="#fff"
                strokeWidth={0.85}
                onMouseEnter={() => {
                  if (showTooltip && geo.properties.name) {
                    setTooltipContent(`${geo.properties.name}: ${countryData ? countryData.value : 0}`);
                  }
                }}
                onMouseLeave={() => {
                  if (showTooltip) {
                    setTooltipContent('');
                  }
                }}
              />
            );
          })}
        </Geographies>
      </ComposableMap>
      {showTooltip && tooltipContent && (
        <StyledTooltip id="geo-heatmap-tooltip" delayShow={50}>
          <Box pad={{ vertical: '0.2rem' }}>
            {tooltipContent}
          </Box>
        </StyledTooltip>
      )}
    </Box>
  );
};

GeoHeatmap.propTypes = {
  data: PropTypes.objectOf(PropTypes.number),
  showTooltip: PropTypes.bool,
};

export default GeoHeatmap;
