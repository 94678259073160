import {
  CYCLOPS_AUTHED_DOMAINS_LOADED,
  CYCLOPS_AUTHED_DOMAINS_CLEAR,
} from '../../actionTypes';

import * as api from '../../apiHelper';
import { initiateRedirectRequest } from '../../redirectActions';
import {
  setFetchLoaderLoading,
  setFetchLoaderReset,
} from '../../fetchLoaderActions';
import { arriveNotice } from '../../noticeActions';
import { setFaviconLoading, setFaviconDefault } from '../../faviconActions';


// Reducer corresponding actions
function cyclopsAuthedDomainsClear() {
  return { type: CYCLOPS_AUTHED_DOMAINS_CLEAR };
}

function cyclopsAuthedDomainsLoaded(authedDomains) {
  return {
    type: CYCLOPS_AUTHED_DOMAINS_LOADED,
    payload: { authedDomains },
  };
}

// API
async function getAuthedDomains(args, token) {
  const response = await api.get('cyclops/authed_domains', args, token, 'v3');
  return response.json();
}

async function submitNewAuthedDomainApi(body, token) {
  const response = await api.post('cyclops/authed_domains', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function editAuthedDomainApi(body, token) {
  const response = await api.put('cyclops/authed_domains', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function deleteAuthedDomainApi(body, token) {
  await api.del('cyclops/authed_domains', body, token, 'v3', true);
}

// Helper
async function fetchAuthedDomains(dispatch, args, token) {
  try {
    const response = await getAuthedDomains(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsAuthedDomainsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
export function clearCyclopsAuthedDomainsRequest() {
  return (dispatch) => {
    dispatch(cyclopsAuthedDomainsClear());
  };
}

export function initiateCyclopsAuthedDomainsRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchAuthedDomains(dispatch, args, token);
  };
}

export function submitNewCyclopsAuthedDomainRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      const { notice } = await submitNewAuthedDomainApi(body, token);

      dispatch(arriveNotice(notice, 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function editCyclopsAuthedDomainRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      const { notice } = await editAuthedDomainApi(body, token);

      dispatch(arriveNotice(notice, 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function deleteCyclopsAuthedDomainRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      await deleteAuthedDomainApi(body, token);

      dispatch(initiateRedirectRequest(null, redirectPath, true));
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}
