import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import uniqid from 'uniqid';
import _ from 'lodash';
import Case from 'case';

import {
  Box, Text, Image, AccordionPanel,
} from 'grommet';

import {
  authLogOutRequest,
  authUploadCompanyLogoRequest,
  initiateCompanySettingsRequest,
} from '@Actions';

import { SystemStatusNotice } from '@Components/Control';
import { paths } from '@Components/configs';
import {
  NavbarSmallLayout,
  NavbarAuthLayout,
  MenuDrop,
  ProfileSection,
  MobileMenuButton,
  MenuAnchor,
} from './HeaderComponent';
import {
  StyledAnchorButton, StyledProfileDropDownAnchor, StyledAccordion,
} from './StyledHeader';

import style from './Header.module.scss';

const AuthHeader = ({
  small,
  isAuthenticated,
  location,
  logOut,
  uploadCompanyLogo,
  fetchCompanySettings,
  companySettings = null,
  auth = null,
  authPagesConfig = null,
  reportsConfig = null,
  darkMode = false,
  searchEnabled = false,
  cyclopsEnabled = false,
}) => {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [isScroll, setIsScroll] = React.useState(false);
  const [collapseOverride, setCollapseOverride] = React.useState(false);
  const authNavbarRef = React.useRef(null);
  const companyLogoInputRef = React.useRef(null);
  const { id } = useParams();

  const checkBackgroundColor = () => {
    if (showOverlay || collapseOverride || small) {
      return;
    }

    const navbar = authNavbarRef.current;

    if (!navbar) return;

    const scrollThreshold = Math.ceil(navbar.getBoundingClientRect().top + 25);

    if (window.scrollY < scrollThreshold) {
      setIsScroll(false);
    } else {
      setIsScroll(true);
    }
  };

  React.useEffect(() => {
    if (!companySettings) fetchCompanySettings();

    window.addEventListener('scroll', () => checkBackgroundColor());

    if (location.pathname === '/piracy-overview' || (id && location.pathname === `/custom-reports/${id}`)) {
      setCollapseOverride(true);
    } else {
      setCollapseOverride(false);
    }

    return () => {
      window.removeEventListener('scroll', () => checkBackgroundColor());
    };
  }, [location.pathname]);

  if (!isAuthenticated || !auth) {
    return null;
  }

  const {
    email,
    firstName,
    lastName,
    companyId,
    companyName,
    companyLogo,
    companyFeatures,
  } = auth;

  const {
    headerBg,
    navBorder,
    primaryText,
    buttonHighlight,
    highlightText,
    altHighlightText,
    hoverColor,
    listMenuMap,
    hideBlacklightSearchCompanies,
    accountPlaceholder,
    showStatusMessage,
    statusMessageConfig,
    loginIconLight,
    loginIconDark,
    loginIconHighlight,
    darkModeColors,
  } = authPagesConfig;

  const selectFeatureRedirect = (key) => {
    switch (key) {
      case 'review':
        return paths.productReview;
      case 'summary':
        return null;
      default:
        return null;
    }
  };

  const useFeatures = companyFeatures && _.filter(companyFeatures, (({ key }) => key === 'export' || key === 'review'));
  const overviewData = companyFeatures && _.find(companyFeatures, { key: 'overview_embed' });
  const overviewEnabled = overviewData?.enabled && overviewData?.config?.embedId !== null;
  const showCustomReports = reportsConfig
    && _.find(reportsConfig, (({ metadata }) => metadata.config.companyId === companyId));
  const hideSearchFeatures = hideBlacklightSearchCompanies.includes(companyId) || !searchEnabled;
  const settingsData = companyFeatures && _.find(companyFeatures, { key: 'setting' });
  const settingsEnabled = settingsData?.enabled && companySettings;

  const cyclopsData = companyFeatures && _.find(companyFeatures, { key: 'cyclops' });
  const isCyclopsDemo = cyclopsData?.config?.demoOnly;
  const cyclopsAnalyticsDisabled = isCyclopsDemo && cyclopsData?.config?.disableAnalytics;
  const cyclopsSettingsDisabled = isCyclopsDemo && cyclopsData?.config?.disableSettings;

  const usePrimaryText = darkMode ? darkModeColors.primaryText : primaryText;
  const useHighlightText = darkMode ? darkModeColors.primaryText : highlightText;
  const useAltHighlightText = darkMode ? darkModeColors.secondaryText : altHighlightText;
  const useHoverColor = darkMode ? darkModeColors.menuButtonHighlight : hoverColor;
  const useNavBg = darkMode ? darkModeColors.containerBg : headerBg;
  const useNavBorder = darkMode ? darkModeColors.navBorder : navBorder;

  if (small) {
    return (
      <NavbarSmallLayout
        darkBackground={darkMode}
        navbarRef={authNavbarRef}
        toggle={() => setShowOverlay(!showOverlay)}
        showOverlay={showOverlay}
        navBg={useNavBg}
        navBorder={useNavBorder}
        primaryTextColor={usePrimaryText}
        secondaryMenuContent={(
          <MobileMenuButton
            label="Sign Out"
            path={paths.login}
            onClick={() => {
              logOut();
              setShowOverlay(!showOverlay);
            }}
            buttonColor={buttonHighlight}
            icon={(
              <Box flex={false} width="20px" height="20px" margin={{ right: '12px' }}>
                <Image
                  src={loginIconLight}
                  alt="Sign out"
                  fit="contain"
                  width="20px"
                  height="20px"
                />
              </Box>
            )}
          />
        )}
      >
        <Box flex direction="column" gap="1.5rem" pad={{ top: '1.5rem' }}>
          <StyledAccordion
            iconColor={darkMode ? 'white' : '#636363'}
            highlightColor={darkMode ? darkModeColors.menuButtonHighlight : '#F4F4F4'}
          >
            {(overviewEnabled || showCustomReports) && (
              <AccordionPanel label={<MenuAnchor label="Content Protection Hub" color={usePrimaryText} />}>
                <Box align="stretch" pad={{ horizontal: '1rem' }}>
                  {overviewEnabled && (
                    <MenuAnchor
                      path={paths.productPiracyOverview}
                      label="Piracy overview"
                      onClick={() => setShowOverlay(!showOverlay)}
                      color={usePrimaryText}
                      className={style.accordionItem}
                    />
                  )}
                  {showCustomReports && (
                    <MenuAnchor
                      path={paths.productCustomReports}
                      label="Custom reports"
                      onClick={() => setShowOverlay(!showOverlay)}
                      color={usePrimaryText}
                      className={style.accordionItem}
                    />
                  )}
                </Box>
              </AccordionPanel>
            )}
            {!hideSearchFeatures && (
              <AccordionPanel label={<MenuAnchor label="Blacklight" color={usePrimaryText} />}>
                <Box align="stretch" pad={{ horizontal: '1rem' }}>
                  {listMenuMap && listMenuMap.map(({ uid, display }) => (
                    <MenuAnchor
                      key={uid}
                      path={paths.productListSearches.replace(':status', uid)}
                      label={display}
                      onClick={() => setShowOverlay(!showOverlay)}
                      color={usePrimaryText}
                      className={style.accordionItem}
                    />
                  ))}
                </Box>
              </AccordionPanel>
            )}
            {settingsEnabled && (
              <AccordionPanel label={<MenuAnchor label="Settings" color={usePrimaryText} />}>
                <Box align="stretch" pad={{ horizontal: '1rem' }}>
                  {companySettings.map(({ api, display }) => (
                    <MenuAnchor
                      key={api}
                      path={paths.settings.replace(':id', api)}
                      label={display}
                      onClick={() => setShowOverlay(!showOverlay)}
                      color={usePrimaryText}
                      className={style.accordionItem}
                    />
                  ))}
                </Box>
              </AccordionPanel>
            )}
            {cyclopsEnabled && (
              <AccordionPanel label={<MenuAnchor label="Cyclops" color={usePrimaryText} />}>
                <Box align="stretch" pad={{ horizontal: '1rem' }}>
                  {!cyclopsAnalyticsDisabled && (
                    <MenuAnchor
                      path={paths.cyclopsAnalytics}
                      label="Analytics"
                      onClick={() => setShowOverlay(!showOverlay)}
                      color={usePrimaryText}
                      className={style.accordionItem}
                    />
                  )}
                  {!isCyclopsDemo && (
                    <MenuAnchor
                      path={paths.cyclopsOrganizations}
                      label="Organizations"
                      onClick={() => setShowOverlay(!showOverlay)}
                      color={usePrimaryText}
                      className={style.accordionItem}
                    />
                  )}
                  <MenuAnchor
                    path={paths.cyclopsEventsList.replace(':status', 'live')}
                    label="Live events"
                    onClick={() => setShowOverlay(!showOverlay)}
                    color={usePrimaryText}
                    className={style.accordionItem}
                  />
                  <MenuAnchor
                    path={paths.cyclopsEventsList.replace(':status', 'past')}
                    label="Past events"
                    onClick={() => setShowOverlay(!showOverlay)}
                    color={usePrimaryText}
                    className={style.accordionItem}
                  />
                  {!cyclopsSettingsDisabled && (
                    <MenuAnchor
                      path={paths.cyclopsSettings}
                      label="Settings"
                      onClick={() => setShowOverlay(!showOverlay)}
                      color={usePrimaryText}
                      className={style.accordionItem}
                    />
                  )}
                </Box>
              </AccordionPanel>
            )}
          </StyledAccordion>
          <Box
            flex={false}
            direction="column"
            gap="1rem"
            pad={{ top: '2rem', left: '2.2rem' }}
            border={{
              side: 'top', color: useNavBorder, style: 'solid', size: '1px',
            }}
          >
            <Box>
              <Text
                uppercase
                size="15px"
                weight={600}
                color={useAltHighlightText}
                style={{ letterSpacing: '2.8px' }}
              >
                Quick Actions
              </Text>
            </Box>
            <Box>
              {!hideSearchFeatures && (
                <>
                  <MenuAnchor
                    path={paths.productNewSearch}
                    label="New Search"
                    onClick={() => setShowOverlay(!showOverlay)}
                    color={usePrimaryText}
                  />
                  <MenuAnchor
                    path={paths.productResultsUpload}
                    label="Upload Results"
                    onClick={() => setShowOverlay(!showOverlay)}
                    color={usePrimaryText}
                  />
                </>
              )}
              {useFeatures?.length > 0 && useFeatures.map(({ key, enabled }) => enabled && (
                <MenuAnchor
                  key={key}
                  path={selectFeatureRedirect(key)}
                  label={Case.capital(key)}
                  onClick={(key === 'export') ? (
                    () => { setShowOverlay(!showOverlay); }
                  ) : (
                    () => setShowOverlay(!showOverlay)
                  )}
                  color={usePrimaryText}
                />
              ))}
              <MenuAnchor
                path={paths.account}
                label="Account"
                onClick={() => setShowOverlay(!showOverlay)}
                color={usePrimaryText}
              />
              <MenuAnchor
                path={paths.users}
                label="Manage Users"
                onClick={() => setShowOverlay(!showOverlay)}
                color={usePrimaryText}
              />
            </Box>
          </Box>
          <Box flex direction="column" justify="end" pad={{ bottom: '1.5rem', horizontal: '1.85rem' }} gap="1.5rem">
            <ProfileSection
              small
              title={companyName}
              subtitle={`${firstName} ${lastName} - ${email}`}
              logoUrl={companyLogo}
              placeholder={accountPlaceholder}
              outlineColor={buttonHighlight}
              textColor={usePrimaryText}
            />
            {showStatusMessage && statusMessageConfig && (
              <SystemStatusNotice statusMessageConfig={statusMessageConfig} textSize="1rem" />
            )}
          </Box>
        </Box>
      </NavbarSmallLayout>
    );
  }

  return (
    <NavbarAuthLayout
      navbarRef={authNavbarRef}
      isScroll={collapseOverride ? true : isScroll}
      navBg={useNavBg}
      darkMode={darkMode}
      borderColor={darkMode ? darkModeColors.navBorder : null}
    >
      <input
        hidden
        key={uniqid()}
        type="file"
        accept="image/*"
        onChange={(e) => uploadCompanyLogo(e.currentTarget.files[0])}
        ref={companyLogoInputRef}
      />
      <Box direction="row" flex={{ grow: 2 }} justify="center" gap="0.5rem">
        {!hideSearchFeatures && (
          <Box direction="row" gap="0.5rem" alignSelf="start">
            <StyledAnchorButton
              path={paths.productNewSearch}
              label="New Search"
              color={usePrimaryText}
              buttonHoverColor={useHoverColor}
              highlightText={useHighlightText}
            />
            <StyledAnchorButton
              path={paths.productResultsUpload}
              label="Upload Results"
              color={usePrimaryText}
              buttonHoverColor={useHoverColor}
              highlightText={useHighlightText}
            />
          </Box>
        )}
        {useFeatures?.length > 0 && (
          <Box
            flex={false}
            direction="row"
            gap="0.5rem"
            alignSelf="start"
            pad={{ left: '0.5rem' }}
          >
            {useFeatures.map(({ key, enabled }) => enabled && (
              <StyledAnchorButton
                key={key}
                path={selectFeatureRedirect(key)}
                label={Case.capital(key)}
                onClick={(key === 'export') ? () => null : () => null}
                color={usePrimaryText}
                buttonHoverColor={useHoverColor}
                highlightText={useHighlightText}
              />
            ))}
          </Box>
        )}
      </Box>
      <MenuDrop
        navBg={useNavBg}
        profileProps={{
          darkBackground: darkMode,
          small: false,
          outlineColor: buttonHighlight,
          title: companyName,
          subTitle: `${firstName} ${lastName}`,
          logoUrl: companyLogo,
          placeholder: accountPlaceholder,
          textColor: usePrimaryText,
          iconColor: darkMode ? 'white' : '#636363',
        }}
      >
        {(closeHover) => (
          <Box pad="12px">
            <Box pad={{ vertical: '0.5rem', horizontal: '1rem' }} direction="column" gap="1rem">
              {showStatusMessage && statusMessageConfig && (
                <SystemStatusNotice statusMessageConfig={statusMessageConfig} width={{ max: '14rem' }} textSize="0.75rem" />
              )}
              <Text size="0.85rem" color={darkMode ? darkModeColors.secondaryText : altHighlightText}>
                {email}
              </Text>
            </Box>
            <StyledProfileDropDownAnchor
              label="Account"
              onClick={closeHover}
              href={paths.account}
              primaryText={usePrimaryText}
              hoverColor={useHoverColor}
              highlightText={useHighlightText}
            />
            <StyledProfileDropDownAnchor
              label="Manage Users"
              onClick={closeHover}
              href={paths.users}
              primaryText={usePrimaryText}
              hoverColor={useHoverColor}
              highlightText={useHighlightText}
            />
            <StyledProfileDropDownAnchor
              onClick={() => companyLogoInputRef.current.click()}
              label="Change Company Logo"
              primaryText={usePrimaryText}
              hoverColor={useHoverColor}
              highlightText={useHighlightText}
            />
            <StyledProfileDropDownAnchor
              hoverColor={useHoverColor}
              primaryText={usePrimaryText}
              highlightText={useHighlightText}
              iconSrc={darkMode ? loginIconLight : loginIconDark}
              iconHoverSrc={darkMode ? loginIconLight : loginIconHighlight}
              label={(
                <Box direction="row" align="center">
                  <Box flex={false} width="21px" height="21px" margin={{ right: '12px' }}>
                    <Box width="21px" height="21px" className="loginHighlight" title="Sign out" />
                  </Box>
                  Sign Out
                </Box>
              )}
              onClick={logOut}
            />
          </Box>
        )}
      </MenuDrop>
    </NavbarAuthLayout>
  );
};

function mapStateToProps(state) {
  const {
    isAuthenticated,
    email,
    firstName,
    lastName,
    companyId,
    companyName,
    companyLogo,
    companyFeatures,
    withAccount,
  } = state.auth;
  const { companySettings } = state.companySettings;

  const auth = !isAuthenticated ? null : {
    email,
    firstName,
    lastName,
    companyId,
    companyName,
    companyLogo,
    companyFeatures,
    withAccount,
  };

  return {
    isAuthenticated,
    auth,
    companySettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    logOut: authLogOutRequest,
    uploadCompanyLogo: authUploadCompanyLogoRequest,
    fetchCompanySettings: initiateCompanySettingsRequest,
  }, dispatch);
}

AuthHeader.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  auth: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    companyName: PropTypes.string.isRequired,
    companyLogo: PropTypes.string,
    companyFeatures: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
    }).isRequired).isRequired,
    withAccount: PropTypes.bool.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  logOut: PropTypes.func.isRequired,
  uploadCompanyLogo: PropTypes.func.isRequired,
  small: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    sideNavBg: PropTypes.string.isRequired,
    headerBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    altHighlightText: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    icons: PropTypes.objectOf(PropTypes.any).isRequired,
    accountPlaceholder: PropTypes.string.isRequired,
    listMenuMap: PropTypes.arrayOf(PropTypes.shape({
      uid: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    hideBlacklightSearchCompanies: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    showStatusMessage: PropTypes.bool,
    statusMessageConfig: PropTypes.shape({
      message: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired,
      statusDotColor: PropTypes.string.isRequired,
    }),
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
      navBorder: PropTypes.string.isRequired,
    }).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        customReports: PropTypes.arrayOf(PropTypes.shape({
          embedCover: PropTypes.string.isRequired,
          embedId: PropTypes.string.isRequired,
          embedTitle: PropTypes.string.isRequired,
          filterById: PropTypes.bool,
          isFavorite: PropTypes.bool,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  })),
  fetchCompanySettings: PropTypes.func.isRequired,
  companySettings: PropTypes.arrayOf(PropTypes.shape({
    api: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  })),
  darkMode: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  cyclopsEnabled: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthHeader);
