import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import uniqid from 'uniqid';

import { Box, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { SingleTextInput, AddButton } from './ProductFormComponent';
import MultipleTagInputs from './MultipleTagInputs';
import { StyledFormTextArea } from './StyledProductFormComponent';

const CalendarInput = loadable(() => import('./CalendarInput'));

const renderNewInput = () => ({ id: uniqid(), val: '', added: false });
const extractValues = (data) => (data.filter((dt) => dt.added).map((dt) => dt.val));

const MultipleGroupInputs = ({
  small,
  inputConfig = null,
  authPagesConfig,
  today,
  useNextDay = false,
  maxInputs = null,
  inputType,
  handleFormValues,
  charLimit = null,
  ...rest
}) => {
  const [inputs, setInputs] = React.useState([renderNewInput()]);

  const addInput = () => {
    const prevInputs = [...inputs];

    if (Object.values(prevInputs[prevInputs.length - 1].val).every((v) => v || (v === false))) {
      prevInputs[prevInputs.length - 1].added = true;

      if (!maxInputs || prevInputs.length < maxInputs) {
        prevInputs.push(renderNewInput(inputType));
      }

      setInputs(prevInputs);
      handleFormValues(extractValues(inputs));
    }
  };

  const removeInput = (index) => {
    const prevInputs = [...inputs];
    const remInputs = prevInputs.filter((_, i) => i !== index);
    const { length } = remInputs;

    if (remInputs[length - 1].added) {
      remInputs.push(renderNewInput(inputType));
    }

    setInputs(remInputs);
    handleFormValues(extractValues(remInputs));
  };

  const handleValue = (index, e) => {
    const valueInputs = [...inputs];
    valueInputs[index].val = e.target.value;

    setInputs(valueInputs);
  };

  const handleDate = (index, date) => {
    if (date) {
      const dateInputs = [...inputs];
      dateInputs[index].val = date;

      setInputs(dateInputs);
    }
  };

  const handleMultiValue = (index, values) => {
    const multiValInputs = [...inputs];

    multiValInputs[index].val = values && values.length > 0 ? JSON.stringify(values) : '';

    setInputs(multiValInputs);
  };

  const { focusHighlight } = authPagesConfig;

  const renderInput = (i) => {
    switch (inputType) {
      case 'textArea':
        return (
          <StyledFormTextArea
            fill
            resize="vertical"
            rows="3"
            disabled={inputs[i].added}
            value={inputs[i].val || ''}
            onChange={(e) => handleValue(i, e)}
            highlight={focusHighlight}
            maxlength={charLimit}
          />
        );

      case 'date':
        return (
          <CalendarInput
            key={uniqid()}
            authPagesConfig={authPagesConfig}
            inputConfig={inputConfig}
            small={small}
            value={inputs[i].val || undefined}
            handleFormValues={(date) => handleDate(i, date)}
            today={today}
            useNextDay={useNextDay}
          />
        );

      case 'sets':
        return (
          <MultipleTagInputs
            small={small}
            inputConfig={inputConfig}
            disabled={inputs[i].added}
            maxInputs={maxInputs}
            handleFormValues={(values) => handleMultiValue(i, values)}
            {...rest}
          />
        );

      default:
        return (
          <SingleTextInput
            focusHighlight={focusHighlight}
            disabled={inputs[i].added}
            small={small}
            value={inputs[i].val || ''}
            handleFormValues={(e) => handleValue(i, e)}
          />
        );
    }
  };

  return (
    <Box gap={small ? 'medium' : 'small'}>
      {inputs.map(({ id, added, val }, i) => (
        <Box key={id}>
          <Box flex direction="row" gap="0.75rem">
            <Box flex key={id} pad={{ bottom: '0.75rem' }} justify="center">
              {renderInput(i)}
            </Box>
            {added && (
              <Box width="1rem" align="center" justify="center" pad={{ bottom: '0.75rem' }}>
                <Button
                  plain
                  icon={<FormClose size="1.25rem" color="#636363" />}
                  onClick={() => removeInput(i)}
                />
              </Box>
            )}
          </Box>
          {!added && (
            <AddButton
              disabled={val === '' || Object.values(val).some((x) => x === '')}
              onClick={() => addInput()}
              highlight={focusHighlight}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

MultipleGroupInputs.propTypes = {
  small: PropTypes.bool.isRequired,
  maxInputs: PropTypes.number,
  inputType: PropTypes.oneOf(['text', 'sets', 'date', 'textArea']).isRequired,
  handleFormValues: PropTypes.func.isRequired,
  authPagesConfig: PropTypes.shape({
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
  }).isRequired,
  inputConfig: PropTypes.objectOf(PropTypes.any),
  today: PropTypes.instanceOf(Date).isRequired,
  useNextDay: PropTypes.bool,
  charLimit: PropTypes.number,
};

export default MultipleGroupInputs;
