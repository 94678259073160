import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  startOfDay, subDays, startOfYear,
} from 'date-fns';

import {
  Box, Text,
} from 'grommet';

import { SelectButton } from './CyclopsComponents';
import GeoHeatmap from './GeoHeatmap';
import { NoDataPlaceholder, FlexibleSelectDropdown } from '../SharedComponents';
import { StyledCyclopsContainer } from './StyledCyclopsComponents';


const QueryControls = ({
  small,
  queryHasValue,
  updateQuery,
  resetQuery,
  setSelectedQueryKey,
  selectedQueryKey,
  today,
  buttonBg,
  buttonTextColor,
  companyOrganizations = null,
  selectedOrganization = null,
  livestreamsData = null,
  selectedLivestream = null,
  piracyStreamData = null,
  selectedPiracyStream = null,
}) => {
  const handleDateSelection = (val) => {
    const useValue = val.getTime();
    updateQuery('since', useValue);
  };

  const formatDate = (date, endDate = null) => {
    const initialDate = date.toLocaleDateString('en-US', {
      month: 'short', day: '2-digit', year: 'numeric',
    });

    if (endDate) {
      const finalDate = endDate.toLocaleDateString('en-US', {
        month: 'short', day: '2-digit', year: 'numeric',
      });

      return `${initialDate} - ${finalDate}`;
    }

    return initialDate;
  };

  const renderDateRange = (key) => {
    switch (key) {
      case 'today':
        return formatDate(today);
      case 'week':
        return formatDate(subDays(today, 7), today);
      case '1-month':
        return formatDate(subDays(today, 30), today);
      case '3-months':
        return formatDate(subDays(today, 90), today);
      case 'ytd':
        return formatDate(startOfYear(today), today);
      default:
        return formatDate(today);
    }
  };

  const DATE_RANGES = {
    today: {
      label: 'Today',
      getDate: (current) => startOfDay(current),
    },
    week: {
      label: '1 Week',
      getDate: (current) => subDays(current, 7),
    },
    '1-month': {
      label: '1 Month',
      getDate: (current) => subDays(current, 30),
    },
    '3-months': {
      label: '3 Months',
      getDate: (current) => subDays(current, 90),
    },
    ytd: {
      label: 'YTD',
      getDate: (current) => startOfYear(current),
    },
  };

  const selectedOrg = selectedOrganization
    ? _.find(companyOrganizations, { Id: selectedOrganization })
    : null;
  const selectedOrgDisplay = selectedOrg?.name;

  const selectedStream = selectedOrg && selectedLivestream
    ? _.find(livestreamsData, { Id: selectedLivestream })
    : null;
  const selectedLivestreamDisplay = selectedStream?.name;

  const selectedPirateStream = selectedOrg && selectedStream && selectedPiracyStream
    ? _.find(piracyStreamData, { Id: selectedPiracyStream })
    : null;
  const selectedPiracyStreamDisplay = selectedPirateStream?.piracyUrl;

  return (
    <Box direction="column" gap="0.5rem">
      <Box wrap direction="row" gap={small ? '0.75rem' : '0.5rem'} align="center">
        {Object.entries(DATE_RANGES).map(([key, { label, getDate }]) => (
          <Box key={key} pad={{ vertical: '0.5rem' }}>
            <SelectButton
              label={label}
              color={buttonTextColor}
              hoverColor="white"
              bgColor={buttonBg}
              selected={selectedQueryKey === key}
              onClick={() => {
                handleDateSelection(getDate(today));
                setSelectedQueryKey(key);
              }}
            />
          </Box>
        ))}
        {companyOrganizations?.length > 0 && (
          <Box pad={{ vertical: '0.5rem' }}>
            <FlexibleSelectDropdown
              noBorder
              buttonWidth="9rem"
              buttonJustify="between"
              flexibleOptions={companyOrganizations}
              selectedOption={selectedOrgDisplay}
              handleItemSelect={(val) => updateQuery('content_owner_id', val)}
              textColor={buttonTextColor}
              displayKey="name"
              valueKey="Id"
              noSelectionLabel="Organization"
              buttonBg={buttonBg}
              selectMenuBg="#004FFE"
              selectMenuHighlight="white"
            />
          </Box>
        )}
        {selectedOrg && livestreamsData?.length > 0 && (
          <Box pad={{ vertical: '0.5rem' }}>
            <FlexibleSelectDropdown
              noBorder
              buttonWidth="9rem"
              buttonJustify="between"
              flexibleOptions={livestreamsData}
              selectedOption={selectedLivestreamDisplay}
              handleItemSelect={(val) => updateQuery('live_stream_id', val)}
              textColor={buttonTextColor}
              displayKey="name"
              valueKey="Id"
              noSelectionLabel="Livestream"
              buttonBg={buttonBg}
              selectMenuBg="#004FFE"
              selectMenuHighlight="white"
            />
          </Box>
        )}
        {selectedOrg && selectedStream && piracyStreamData?.length > 0 && (
          <Box pad={{ vertical: '0.5rem' }}>
            <FlexibleSelectDropdown
              noBorder
              buttonWidth="9rem"
              buttonJustify="between"
              flexibleOptions={piracyStreamData}
              selectedOption={selectedPiracyStreamDisplay}
              handleItemSelect={(val) => updateQuery('piracy_stream_id', val)}
              textColor={buttonTextColor}
              displayKey="piracyUrl"
              valueKey="Id"
              noSelectionLabel="Piracy Stream"
              buttonBg={buttonBg}
              selectMenuBg="#004FFE"
              selectMenuHighlight="white"
            />
          </Box>
        )}
        {queryHasValue && (
          <Box pad={{ vertical: '0.5rem' }}>
            <SelectButton
              label="Reset"
              color={buttonTextColor}
              hoverColor="white"
              bgColor={buttonBg}
              onClick={() => {
                resetQuery();
                setSelectedQueryKey(null);
              }}
            />
          </Box>
        )}
      </Box>
      <Text color={buttonTextColor} size="1rem" weight={500}>
        {renderDateRange(selectedQueryKey)}
      </Text>
    </Box>
  );
};

QueryControls.propTypes = {
  small: PropTypes.bool.isRequired,
  queryHasValue: PropTypes.bool.isRequired,
  updateQuery: PropTypes.func.isRequired,
  resetQuery: PropTypes.func.isRequired,
  setSelectedQueryKey: PropTypes.func.isRequired,
  selectedQueryKey: PropTypes.string,
  today: PropTypes.instanceOf(Date).isRequired,
  buttonBg: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  selectedOrganization: PropTypes.string,
  companyOrganizations: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  selectedLivestream: PropTypes.string,
  livestreamsData: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  selectedPiracyStream: PropTypes.string,
  piracyStreamData: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    piracyUrl: PropTypes.string.isRequired,
    pirateId: PropTypes.string.isRequired,
  })),
};

const AnalyticsPartial = ({
  small,
  loading,
  authPagesConfig,
  query,
  updateQuery,
  resetQuery,
  selectedQueryKey,
  setSelectedQueryKey,
  today,
  analyticsData = null,
  companyOrganizations = null,
  livestreamsData = null,
  piracyStreamData = null,
}) => {
  const {
    darkModeColors: {
      primaryDarkBg,
      containerBg,
      primaryText,
      menuButtonHighlight,
      secondaryText,
    },
  } = authPagesConfig;

  const dataTitleMap = {
    authedViewership: 'Authorized viewers',
    averageTimeToKill: 'Average time to kill (minutes)',
    pirateStreamsN: 'Unauthorized streams detected',
    pirateStreamsTraffic: 'Estimated illegal viewers',
    streamsShutdown: 'Unauthorized streams shut down',
    uniqueDevicesAboveThresholdN: 'Users with two or more unique devices',
  };

  const renderValueText = (val) => {
    if (val === null) return 'N/A';

    return val.toLocaleString();
  };

  const queryHasValue = _.find(query, (value) => value !== null) !== undefined;
  const showPiracyHeatmap = !_.isEmpty(analyticsData?.pirateGeoStats);

  /* eslint-disable camelcase */
  const renderContent = () => {
    if (loading || !analyticsData) {
      return (
        <NoDataPlaceholder
          noShadow
          darkmode
          backgroundColor={containerBg}
          buttonHighlight={menuButtonHighlight}
          loading={loading}
          label={loading ? 'Loading analytics data...' : 'No analytics data available'}
          textColor={primaryText}
          loaderColor={menuButtonHighlight}
          showRefreshButton={!loading && !analyticsData && queryHasValue}
          refreshQuery={resetQuery}
        />
      );
    }

    const dataKeys = Object.keys(analyticsData).filter((key) => key !== 'pirateGeoStats');

    return (
      <Box direction="column" gap="1rem">
        <Box wrap direction="row" justify="between">
          {dataKeys.map((key) => (
            <Box basis={small ? 'full' : '1/3'} pad={{ vertical: '1rem', horizontal: small ? '0' : '1rem' }} margin={{ left: small ? '0' : '-1rem' }} key={key}>
              <StyledCyclopsContainer
                background={containerBg}
                direction="column"
                gap="1rem"
                width="100%"
              >
                <Text size="1rem" weight={500} color={secondaryText}>{dataTitleMap[key]}</Text>
                <Box direction="row" gap="0.5rem" align="end">
                  <Text size="1.5rem" weight={600} color={primaryText}>{renderValueText(analyticsData[key])}</Text>
                </Box>
              </StyledCyclopsContainer>
            </Box>
          ))}
        </Box>
        {showPiracyHeatmap && (
          <Box width="100%" pad={{ right: small ? '0' : '1rem' }}>
            <StyledCyclopsContainer
              background={containerBg}
              direction="column"
              width="100%"
              gap="1rem"
              height={small ? '20rem' : '35rem'}
            >
              <Text size="1rem" weight={500} color={secondaryText}>Piracy activity across different regions</Text>
              <GeoHeatmap
                showTooltip
                data={analyticsData?.pirateGeoStats}
              />
            </StyledCyclopsContainer>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      flex
      background={primaryDarkBg}
      direction="column"
      gap="0.5rem"
    >
      <QueryControls
        small={small}
        queryHasValue={queryHasValue}
        updateQuery={updateQuery}
        resetQuery={resetQuery}
        selectedQueryKey={selectedQueryKey}
        setSelectedQueryKey={setSelectedQueryKey}
        today={today}
        buttonBg={containerBg}
        buttonTextColor={secondaryText}
        companyOrganizations={companyOrganizations}
        livestreamsData={livestreamsData}
        piracyStreamData={piracyStreamData}
        selectedOrganization={query.content_owner_id}
        selectedLivestream={query.live_stream_id}
        selectedPiracyStream={query.piracy_stream_id}
      />
      {renderContent()}
    </Box>
  );
};

AnalyticsPartial.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
    }).isRequired,
    cyclopsStatusDisplayMap: PropTypes.objectOf(PropTypes.any.isRequired)
      .isRequired,
  }).isRequired,
  updateQuery: PropTypes.func.isRequired,
  resetQuery: PropTypes.func.isRequired,
  selectedQueryKey: PropTypes.string,
  setSelectedQueryKey: PropTypes.func.isRequired,
  today: PropTypes.instanceOf(Date).isRequired,
  query: PropTypes.shape({
    since: PropTypes.number,
    content_owner_id: PropTypes.string,
    live_stream_id: PropTypes.string,
    piracy_stream_id: PropTypes.string,
  }).isRequired,
  analyticsData: PropTypes.shape({
    authedViewership: PropTypes.number,
    averageTimeToKill: PropTypes.number,
    pirateStreamsN: PropTypes.number,
    pirateStreamsTraffic: PropTypes.number,
    streamsShutdown: PropTypes.number,
    uniqueDevicesAboveThresholdN: PropTypes.number,
    pirateGeoStats: PropTypes.objectOf(PropTypes.number),
  }),
  companyOrganizations: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  livestreamsData: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  piracyStreamData: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    piracyUrl: PropTypes.string.isRequired,
    pirateId: PropTypes.string.isRequired,
  })),
};
/* eslint-enable camelcase */

export default AnalyticsPartial;
