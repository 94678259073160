import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Box, Text, Layer, Button,
} from 'grommet';
import { FormClose } from 'grommet-icons';

import { AppButton } from '@Components/Control';


const StyledCloseContainer = styled(Box)`
  svg { padding-top: 0.1rem };

  &:hover {
    span {
      color: ${({ highlight }) => highlight};
    }

    svg {
      stroke: ${({ highlight }) => highlight};
      fill: ${({ highlight }) => highlight};
    }
  }
`;

const StyledRaisedContainer = styled(Box)`
  border-radius: 0.35rem;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
  background-color: ${({ bgColor }) => bgColor ?? 'white'};
`;

const SimpleModal = ({
  small,
  toggleModal,
  modalTitle,
  textColor,
  highlightColor,
  children,
  closeLabel = 'Finished',
  modalBg = 'white',
  hideLowerSection = false,
  desktopMinWidth = '50rem',
  noClickOutside = false,
}) => (
  <Layer
    responsive={false}
    animate={false}
    onEsc={() => toggleModal(false)}
    onClickOutside={() => (noClickOutside ? null : toggleModal(false))}
  >
    <StyledRaisedContainer
      gap="0.5rem"
      align="center"
      pad="1.5rem"
      width={{ min: small ? '96vw' : desktopMinWidth }}
      bgColor={modalBg}
    >
      <Box direction="row" width="100%" justify="between">
        <Text weight={600} size="1rem" color={textColor}>
          {modalTitle}
        </Text>
        <Button
          plain
          onClick={() => toggleModal(false)}
        >
          <StyledCloseContainer highlight={highlightColor} direction="row" gap="0.25rem" align="center">
            <Text weight={600} size="0.9rem" color={textColor}>
              Close
            </Text>
            <FormClose color={textColor} size="1.2rem" />
          </StyledCloseContainer>
        </Button>
      </Box>
      <Box>
        {children}
      </Box>
      {!hideLowerSection && (
        <Box
          width="100%"
          pad={{ top: '1rem' }}
          align="center"
          border={{
            color: '#D0D0D0',
            size: '1px',
            style: 'solid',
            side: 'top',
          }}
        >
          <AppButton
            overrideHover
            width="7.25rem"
            height="3rem"
            onClick={() => toggleModal(false)}
            level="dynamicLarge"
            color="white"
            bgColor={highlightColor}
            fontWeight={600}
            label={closeLabel}
          />
        </Box>
      )}
    </StyledRaisedContainer>
  </Layer>
);

SimpleModal.propTypes = {
  small: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  modalBg: PropTypes.string,
  closeLabel: PropTypes.string,
  hideLowerSection: PropTypes.bool,
  desktopMinWidth: PropTypes.string,
  noClickOutside: PropTypes.bool,
};

export default SimpleModal;
