import {
  PRODUCT_NEW_FORM_CONFIG_LOADED,
  PRODUCT_NEW_FORM_CONFIG_CLEAR,
  PRODUCT_NEW_FORM_BULK_CONFIG_LOADED,
  PRODUCT_NEW_FORM_BULK_CONFIG_CLEAR,
  PRODUCT_NEW_SEARCHES_USAGE_LOADING,
  PRODUCT_NEW_SEARCHES_USAGE_LOADED,
  PRODUCT_NEW_SEARCHES_USAGE_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  formConfig: null,
  bulkFormConfig: null,
  productUsageData: null,
  usageLoading: false,
};

const productNewForm = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_NEW_FORM_CONFIG_LOADED:
      return { ...state, formConfig: action.payload.formConfig };

    case PRODUCT_NEW_FORM_CONFIG_CLEAR:
      return initialState;

    case PRODUCT_NEW_FORM_BULK_CONFIG_LOADED:
      return { ...state, bulkFormConfig: action.payload.bulkFormConfig };

    case PRODUCT_NEW_FORM_BULK_CONFIG_CLEAR:
      return initialState;

    case PRODUCT_NEW_SEARCHES_USAGE_LOADING:
      return { ...state, usageLoading: true };

    case PRODUCT_NEW_SEARCHES_USAGE_LOADED:
      return {
        ...state,
        productUsageData: action.payload.productUsageData,
        usageLoading: false,
      };

    case PRODUCT_NEW_SEARCHES_USAGE_CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default productNewForm;
