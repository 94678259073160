import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Text,
} from 'grommet';

import { AppButton, ActionButton } from '@Components/Control';
import { ProductFormSingleTextInput } from '@Components/Partial/Product/NewSearch';
import { localAsset } from '@Helpers/asset';
import {
  renderTableHeader,
  NoDataPlaceholder,
  TableWrapper,
} from '../SharedComponents';
import {
  StyledSVG, StyledSortButton,
} from '../StyledSharedComponents';


const TableControls = ({
  highlight,
  primaryText,
  selectedSortOption,
  handleSort,
  handleAdd,
  isAdding,
  iconPath,
}) => (
  <Box direction="row" gap="0.5rem">
    <AppButton
      overrideHover
      onClick={() => handleAdd()}
      level="tableControls"
      color="white"
      bgColor={highlight}
      fontWeight={600}
      label={isAdding ? 'Cancel' : '+ Add Domain'}
    />
    <StyledSortButton
      plain
      overrideHover
      key="sort"
      textSize="1rem"
      color={primaryText}
      label={selectedSortOption.name}
      highlight={highlight}
      onClick={() => handleSort(selectedSortOption.direction === 'desc' ? 'asc' : 'desc')}
      icon={(
        <StyledSVG
          src={localAsset(iconPath)}
          width="1.1rem"
          height="1.1rem"
          title={selectedSortOption.name}
        />
      )}
    />
  </Box>
);

TableControls.propTypes = {
  highlight: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  iconPath: PropTypes.string.isRequired,
  selectedSortOption: PropTypes.shape({
    name: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  handleSort: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
};

const DomainListTable = ({
  small,
  loading,
  domainData = null,
  pageTitle = null,
  headerMap,
  updateDomain,
  authPagesConfig: {
    primaryText,
    buttonHighlight,
  },
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editValues, setEditValues] = React.useState({ newValue: '', originalValue: '' });
  const [isAdding, setIsAdding] = React.useState(false);
  const [addValue, setAddValue] = React.useState('');
  const [sortedData, setSortedData] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState({ name: 'Sort A-Z', direction: 'desc' });

  React.useEffect(() => {
    if (domainData) {
      const initialData = [...domainData];
      const sorted = initialData.sort((a, b) => (a.localeCompare(b)));
      setSortedData(sorted);
    }
  }, [domainData]);

  const handleEdit = (val) => {
    setEditValues({ newValue: val, originalValue: val });
    setIsEditing(true);
  };

  const handleSort = (sortDirection) => {
    const prevData = [...sortedData];

    if (sortDirection === 'desc') {
      prevData.sort((a, b) => (a.localeCompare(b)));
      setSortOrder({ name: 'Sort A-Z', direction: 'desc' });
    } else {
      prevData.sort((a, b) => (b.localeCompare(a)));
      setSortOrder({ name: 'Sort Z-A', direction: 'asc' });
    }

    setSortedData(prevData);
  };

  const editSubmitDisabled = isEditing && editValues.newValue === editValues.originalValue;
  const addSubmitDisabled = isAdding && (!addValue || addValue === '');

  const renderCellChildren = (childValue) => {
    if (isEditing && editValues.originalValue === childValue) {
      return (
        <Box key={`row-${childValue}`} direction="row" width={small ? '100%' : '50%'} gap="0.5rem">
          <Box flex>
            <ProductFormSingleTextInput
              key={childValue}
              focusHighlight={buttonHighlight}
              value={editValues.newValue}
              handleFormValues={(val) => (
                setEditValues({ newValue: val, originalValue: childValue })
              )}
              placeholder="Enter a new domain"
              small={small}
              onClear={() => {
                setEditValues({ newValue: '', originalValue: '' });
                setIsEditing(false);
              }}
              clearActive={isEditing}
            />
          </Box>
          <Box pad={{ vertical: '0.15rem' }}>
            <AppButton
              overrideHover
              onClick={() => {
                updateDomain(editValues.newValue, editValues.originalValue, 'edit');
                setEditValues({ newValue: '', originalValue: '' });
                setIsEditing(false);
              }}
              disabled={editSubmitDisabled}
              level="tableControls"
              color="white"
              bgColor={buttonHighlight}
              fontWeight={600}
              label="Save"
            />
          </Box>
        </Box>
      );
    }

    return (
      <Text size="1rem" color={primaryText}>{childValue}</Text>
    );
  };

  const renderTableContents = () => {
    if (loading || !sortedData || sortedData.length === 0) {
      return (
        <NoDataPlaceholder
          noShadow
          noRound
          showRefreshButton={false}
          buttonHighlight={buttonHighlight}
          loading={loading || !sortedData}
          label="No domains in this category"
          textColor={primaryText}
          loaderColor={buttonHighlight}
        />
      );
    }

    return (
      <Box fill="horizontal" direction="column">
        {sortedData?.map((dt) => (
          <Box
            pad={{ vertical: '0.75rem', horizontal: '0.5rem' }}
            key={dt}
            border={{
              color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
            }}
            direction="row"
            fill="horizontal"
          >
            <Box width={small ? '75%' : '85%'} justify="center">
              {renderCellChildren(dt)}
            </Box>
            <Box width={small ? '25%' : '15%'} pad={{ left: '0.5rem' }}>
              <Box direction="row" round="0.35rem" gap="0.5rem" background="white">
                <ActionButton
                  key="edit-domain"
                  small={small}
                  buttonName="Edit domain"
                  iconPath="images/edit-pencil-icon.svg"
                  textColor={primaryText}
                  hoverColor={buttonHighlight}
                  hoverTextColor="white"
                  actionHandler={() => handleEdit(dt)}
                />
                <ActionButton
                  key="delete-domain"
                  small={small}
                  buttonName="Delete domain"
                  iconPath="images/delete-content-icon.svg"
                  textColor={primaryText}
                  hoverColor={buttonHighlight}
                  hoverTextColor="white"
                  actionHandler={() => updateDomain(dt, null, 'delete')}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box direction="column" fill="horizontal">
      <TableWrapper
        small={small}
        header={pageTitle}
        textColor={primaryText}
        boxPad={{ horizontal: small ? '1.5rem' : '2rem', top: '1rem', bottom: small ? '1.5rem' : '2rem' }}
        tableControls={(
          <TableControls
            small={small}
            highlight={buttonHighlight}
            primaryText={primaryText}
            displayInputValue={null}
            selectedSortOption={sortOrder}
            handleSort={(sortDirection) => handleSort(sortDirection)}
            handleAdd={() => setIsAdding(!isAdding)}
            isAdding={isAdding}
            iconPath={`images/sort-input-icon-${sortOrder.direction}.svg`}
          />
        )}
      >
        <Box>
          {!small && sortedData?.length > 0 && (
            <Box
              fill="horizontal"
              wrap={false}
              direction="row"
              border={{
                color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
              }}
            >
              {headerMap.map(({ uid, display, size }) => (
                <Box key={uid} width={size} pad={{ horizontal: '0.5rem', top: '1rem', bottom: '0.5rem' }}>
                  {renderTableHeader(display, small)}
                </Box>
              ))}
            </Box>
          )}
          {isAdding && (
            <Box
              pad={{ vertical: '0.75rem', horizontal: '0.5rem' }}
              border={{
                color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
              }}
              width="100%"
            >
              <Box width={small ? '100%' : '50%'} direction="row" gap="0.5rem">
                <Box flex>
                  <ProductFormSingleTextInput
                    focusHighlight={buttonHighlight}
                    value={addValue}
                    handleFormValues={(val) => setAddValue(val)}
                    placeholder="Enter a new domain"
                    small={small}
                    onClear={() => {
                      setAddValue('');
                      setIsAdding(false);
                    }}
                    clearActive={isAdding}
                  />
                </Box>
                <Box pad={{ vertical: '0.15rem' }}>
                  <AppButton
                    overrideHover
                    onClick={() => {
                      updateDomain(addValue, null, 'push');
                      setAddValue('');
                      setIsAdding(false);
                    }}
                    disabled={addSubmitDisabled}
                    level="tableControls"
                    color="white"
                    bgColor={buttonHighlight}
                    fontWeight={600}
                    label="Save"
                  />
                </Box>
              </Box>
            </Box>
          )}
          {renderTableContents()}
        </Box>
      </TableWrapper>
    </Box>
  );
};

DomainListTable.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  domainData: PropTypes.arrayOf(PropTypes.string),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    icons: PropTypes.shape({
      seeDetailsIcon: PropTypes.string.isRequired,
      searchPriorityIcon: PropTypes.string.isRequired,
    }).isRequired,
    statusColorMap: PropTypes.shape({
      running: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      completed: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      paused: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      scheduled: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pageTitle: PropTypes.string,
  headerMap: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  updateDomain: PropTypes.func.isRequired,
};

export default DomainListTable;
