export function addPrefetchJpeg(url) {
  const links = document.querySelectorAll(`link[rel="prefetch"][href="${url}"]`);

  if (!links.length) {
    const link = document.createElement('link');
    link.setAttribute('rel', 'prefetch');
    link.setAttribute('href', url);
    link.setAttribute('as', 'image');
    link.setAttribute('type', 'image/jpeg');
    document.head.appendChild(link);
  }
}

export function addPrefetchPng(url, isSmall) {
  const useUrl = isSmall ? `${url}&w=1024` : url;
  const links = document.querySelectorAll(`link[rel="prefetch"][href="${useUrl}"]`);

  if (!links.length) {
    const link = document.createElement('link');
    link.setAttribute('rel', 'prefetch');
    link.setAttribute('href', useUrl);
    link.setAttribute('as', 'image');
    link.setAttribute('type', 'image/png');
    document.head.appendChild(link);
  }
}

const { camelizeKeys } = require('humps');
const QueryString = require('query-string');

export function locationSearchParams(search) {
  return camelizeKeys(QueryString.parse(search));
}

export function locationSearchString(search) {
  return QueryString.stringify(search);
}

const { format } = require('date-fns');
const { UTCDate } = require('@date-fns/utc');

const { isSafari, isEdge } = require('react-device-detect');
const { localAsset } = require('@Helpers/asset');
const documentHead = require('@Helpers/documentHead').default;

function setFavicon(faviconHref) {
  document.querySelector('link[rel="icon"]').href = faviconHref;
}

function setBrowserTabLoading() {
  if (isSafari || isEdge) {
    if (document.title) {
      if (document.title.substr(0, 2) === '! ') {
        document.title = `... ${document.title.substr(2)}`;
      } else if (document.title.substr(0, 4) !== '... ') {
        document.title = `... ${document.title}`;
      }
    } else {
      document.title = `... ${documentHead}`;
    }
  } else {
    setFavicon(localAsset('favicon/loading.png'));

    if (document.title) {
      if (document.title.substr(0, 4) === '... ') {
        document.title = document.title.substr(4);
      } else if (document.title.substr(0, 2) === '! ') {
        document.title = document.title.substr(2);
      }
    } else {
      document.title = documentHead;
    }
  }
}

function setBrowserTabReady() {
  if (!isSafari || isEdge) {
    setFavicon(localAsset('favicon/ready.png'));
  }

  if (document.title) {
    if (document.title.substr(0, 4) === '... ') {
      document.title = `! ${document.title.substr(4)}`;
    } else if (document.title.substr(0, 2) !== '! ') {
      document.title = `! ${document.title}`;
    }
  } else {
    document.title = `! ${documentHead}`;
  }
}

function setBrowserTabDefault() {
  if (!isSafari || isEdge) {
    setFavicon('/favicon.png');
  }

  if (document.title) {
    if (document.title.substr(0, 4) === '... ') {
      document.title = document.title.substr(4);
    } else if (document.title.substr(0, 2) === '! ') {
      document.title = document.title.substr(2);
    }
  } else {
    document.title = documentHead;
  }
}

export function setFaviconStatus(faviconStatus) {
  switch (faviconStatus) {
    case 'loading':
      setBrowserTabLoading();
      break;

    case 'ready':
      setBrowserTabReady();
      break;

    default:
      setBrowserTabDefault();
      break;
  }
}

function getTimeFormat(dateTime, shorten) {
  const time = dateTime.split('T')[1];

  if (shorten) {
    return `M/dd/yy${time ? ' h:mmaaa' : ''}`;
  }

  return `MMM dd, yyyy${time ? ' hh:mmaaa' : ''}`;
}

export function buildDateTimeLabel(date, shorten = false) {
  return format(new UTCDate(date), getTimeFormat(date, shorten));
}

export function buildDateLabel(startDate, endDate, shorten = false) {
  const formattedStartDate = buildDateTimeLabel(startDate, shorten);
  const formattedEndDate = endDate ? buildDateTimeLabel(endDate, shorten) : '—';

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export function buildSingleDateLabel(date, shorten = false) {
  const formattedDate = buildDateTimeLabel(date, shorten);

  return formattedDate;
}

export const shortenInt = (n) => {
  const number = n.toString();
  const len = number.length;
  const place = len % 3 || 3;
  let abb;

  switch (true) {
    case len > 9:
      abb = 'B';
      break;
    case len > 6:
      abb = 'M';
      break;
    case len > 3:
      abb = 'K';
      break;
    default:
      return number;
  }

  return `${number.slice(0, place)}.${number.slice(place, place + 1)}${abb}`;
};

export const formatUnit = (unit, value) => {
  const isSingular = value && (value === 1 || value === '1');
  const displayString = isSingular ? unit : `${unit}s`;

  return displayString;
};

export const secondsSinceEpoch = () => Math.floor(Date.now() / 1000);

export function disableMouseScroll(e) {
  if (e.deltaX || (e.shiftKey && e.deltaY)) {
    e.preventDefault();
    e.stopPropagation();
  }
}

export const isIPadOS = () => (
  window.navigator.platform === 'iPad'
  || (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
);

function isHTML(str) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

export const escapedStringParser = (text) => {
  let htmlText = `"${text}"`;
  if (isHTML(text)) htmlText = JSON.stringify(`${text}`);
  return JSON.parse(htmlText);
};

export function openFile(url) {
  const downloadEl = document.createElement('a');
  downloadEl.href = url;
  downloadEl.download = url.substring(url.lastIndexOf('/') + 1);

  document.body.appendChild(downloadEl);

  downloadEl.click();

  setTimeout(() => document.body.removeChild(downloadEl), 500);
}

export function isValidUrl(string) {
  try {
    const validUrl = new URL(string);
    return validUrl && (validUrl.protocol === 'http:' || validUrl.protocol === 'https:');
  } catch (err) {
    return false;
  }
}

export function logCloudwatchError(level, message, stack) {
  if (process.env.NODE_ENV === 'development') return;

  const url = process.env.AWS_ERROR_LOG_URL;
  const location = window.location.hostname;

  const selectEnvironment = () => {
    switch (location) {
      case 'beta.redflagai.co':
        return 'beta-1';
      case 'beta-2.redflagai.co':
        return 'beta-2';
      case 'beta-3.redflagai.co':
        return 'beta-3';
      case 'beta-4.redflagai.co':
        return 'beta-4';
      case 'beta-5.redflagai.co':
        return 'beta-5';
      case 'beta-6.redflagai.co':
        return 'beta-6';
      default:
        return 'production';
    }
  };

  const meta = {
    agent: navigator.userAgent,
    language: navigator.language,
    level,
    source: 'redflag-auth-app',
    environment: selectEnvironment(),
  };

  const requestBody = JSON.stringify({ meta, message, stack });

  fetch(url, {
    method: 'POST',
    body: requestBody,
  });
}
