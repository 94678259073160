import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Box } from 'grommet';

import { Seo } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { UsersTablePartial } from '@Components/Partial/Product/CompanyUsers';
import { paths } from '@Components/configs';

import {
  fetchUsersRequest,
  clearUsersRequest,
  createNewUserRequest,
  deleteUserRequest,
  resendUserInviteRequest,
} from '@Actions';


const CompanyUsersPage = ({
  small,
  mixpanel,
  location,
  loading,
  submitLoading,
  fetchCompanyUsers,
  clearCompanyUsers,
  createUser,
  deleteUser,
  resendInvite,
  currentUserEmail,
  companyAccountUsers = null,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
}) => {
  React.useEffect(() => {
    fetchCompanyUsers();

    return () => {
      clearCompanyUsers();
    };
  }, []);

  const headerMap = [
    { uid: 'firstName', display: 'First Name', size: small ? '100%' : '15%' },
    { uid: 'lastName', display: 'Last Name', size: small ? '100%' : '15%' },
    { uid: 'email', display: 'Email', size: small ? '100%' : '25%' },
    { uid: 'status', display: 'Status', size: small ? '100%' : '15%' },
    { uid: 'actions', display: 'Actions', size: small ? '100%' : '30%' },
  ];

  const handleDeleteUser = (userId) => {
    deleteUser(userId, paths.users, () => fetchCompanyUsers());
  };

  const handleResendInvite = (userId) => {
    resendInvite(userId, paths.users, () => fetchCompanyUsers());
  };

  const handleCreateUser = (body) => {
    createUser(body, paths.users, () => fetchCompanyUsers());
  };

  return (
    <AuthNavWrapper
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Manage Authorized Users',
        subTitle: 'Account Settings',
        textColor: 'white',
      }}
    >
      <Seo />
      <Box
        flex
        direction="column"
        background="#F1F2FC"
        pad={{ vertical: '1.5rem' }}
        height="100%"
        gap="1rem"
      >
        <UsersTablePartial
          small={small}
          loading={loading || submitLoading}
          headerMap={headerMap}
          createUser={handleCreateUser}
          deleteUser={handleDeleteUser}
          resendInvite={handleResendInvite}
          usersData={companyAccountUsers}
          authPagesConfig={authPagesConfig}
          currentUserEmail={currentUserEmail}
        />
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  const { email } = state.auth;

  return {
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
    companyAccountUsers: state.auth.accountUsers,
    currentUserEmail: email,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCompanyUsers: fetchUsersRequest,
    clearCompanyUsers: clearUsersRequest,
    createUser: createNewUserRequest,
    deleteUser: deleteUserRequest,
    resendInvite: resendUserInviteRequest,
  }, dispatch);
}

CompanyUsersPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        customReports: PropTypes.arrayOf(PropTypes.shape({
          embedCover: PropTypes.string.isRequired,
          embedId: PropTypes.string.isRequired,
          embedTitle: PropTypes.string.isRequired,
          filterById: PropTypes.bool,
          isFavorite: PropTypes.bool,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  })),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  fetchCompanyUsers: PropTypes.func.isRequired,
  clearCompanyUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  companyAccountUsers: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired),
  currentUserEmail: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(CompanyUsersPage));
