import {
  CYCLOPS_ACTIVE_ORGANIZATION_SET,
  CYCLOPS_ACTIVE_ORGANIZATION_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  activeCompanyOrganization: null,
};

const organizations = (state = initialState, action) => {
  switch (action.type) {
    case CYCLOPS_ACTIVE_ORGANIZATION_SET:
      return {
        ...state,
        activeCompanyOrganization: {
          ...action.payload.activeOrganization,
        },
      };

    case CYCLOPS_ACTIVE_ORGANIZATION_CLEAR:
      return {
        ...state,
        activeCompanyOrganization: null,
      };

    default:
      return state;
  }
};

export default organizations;
