import {
  CYCLOPS_PIRACY_STREAMS_LOADED,
  CYCLOPS_PIRACY_STREAMS_CLEAR,
} from '../../actionTypes';

import * as api from '../../apiHelper';
import { initiateRedirectRequest } from '../../redirectActions';
import {
  setSecondaryFetchLoaderLoading,
  setSecondaryFetchLoaderReset,
  setSubmissionLoaderLoading,
  setSubmissionLoaderReset,
} from '../../fetchLoaderActions';
import { arriveNotice } from '../../noticeActions';
import { setFaviconLoading, setFaviconDefault } from '../../faviconActions';


// Reducer corresponding actions
function cyclopsPiracyStreamsClear() {
  return { type: CYCLOPS_PIRACY_STREAMS_CLEAR };
}

function cyclopsPiracyStreamsLoaded(piracyStreams) {
  return {
    type: CYCLOPS_PIRACY_STREAMS_LOADED,
    payload: { piracyStreams },
  };
}

// API
async function getPiracyStreams(args, token) {
  const response = await api.nestedParamsGet('cyclops/piracy_streams', args, token, 'v3');
  return response.json();
}

async function killPiracyStreamApi(body, token) {
  await api.patch('cyclops/piracy_streams/send_kill_switch', body, token, 'v3', true);
}

// Helper
async function fetchPiracyStreams(dispatch, args, token, noLoader) {
  try {
    const response = await getPiracyStreams(args, token);

    if (!noLoader) {
      dispatch(setFaviconDefault());
      dispatch(setSecondaryFetchLoaderReset());
    }

    dispatch(cyclopsPiracyStreamsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      if (!noLoader) {
        dispatch(setFaviconDefault());
        dispatch(setSecondaryFetchLoaderReset());
      }

      dispatch(arriveNotice(e.message));
    }
  }
}

async function killPiracyStream(dispatch, body, token, redirectPath) {
  try {
    await killPiracyStreamApi(body, token);

    dispatch(arriveNotice('Successfully killed stream', 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
export function clearCyclopsPiracyStreamsRequest() {
  return (dispatch) => {
    dispatch(cyclopsPiracyStreamsClear());
  };
}

export function initiateCyclopsPiracyStreamsRequest(args, noLoader = false) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { secondaryDataLoading },
    } = getState();

    if (!secondaryDataLoading && !noLoader) dispatch(setSecondaryFetchLoaderLoading());

    if (faviconStatus !== 'loading' && !noLoader) {
      dispatch(setFaviconLoading());
    }

    await fetchPiracyStreams(dispatch, args, token);
  };
}

export function killCyclopsPiracyStreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await killPiracyStream(dispatch, body, token, redirectPath);
  };
}
