import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uniqid from 'uniqid';

import {
  Box, Layer, Button, Text,
} from 'grommet';
import {
  FormClose,
  StatusWarning,
} from 'grommet-icons';

import { closeCustomNoticeRequest } from '@Actions';


const StyledLayer = styled(Layer)`
  ${({ transparent }) => transparent && 'background: transparent;'}
`;

const StyledText = styled(Text)`
  color: var(--primary-text);
  white-space: pre-line;
`;

const StyledCloseContainer = styled(Box)`
  svg { padding-top: 0.1rem };

  &:hover {
    span {
      color: ${({ highlight }) => highlight};
    }

    svg {
      stroke: ${({ highlight }) => highlight};
      fill: ${({ highlight }) => highlight};
    }
  }
`;

const CustomNotification = ({
  customMessage, closeCustomNotice,
}) => {
  if (!customMessage || typeof customMessage === 'object' || customMessage instanceof Error) {
    return null;
  }

  return (
    <StyledLayer
      transparent
      responsive={false}
      animate={false}
      onEsc={closeCustomNotice}
      onClickOutside={closeCustomNotice}
    >
      <Box
        align="center"
        justify="center"
        direction="column"
        pad="1rem"
        round="10px"
        background="white"
        gap="1rem"
      >
        <Box direction="row" justify="between" width="100%">
          <Box direction="row" gap="0.5rem" align="center">
            <StatusWarning size="1.25rem" color="var(--primary-text)" />
            <Text weight={600} size="1.1rem" color="var(--primary-text)">
              Errors
            </Text>
          </Box>
          <Button
            plain
            onClick={closeCustomNotice}
          >
            <StyledCloseContainer direction="row" gap="0.25rem" align="center" highlight="#004FFE">
              <Text weight={600} size="1rem" color="var(--primary-text)">
                Close
              </Text>
              <FormClose color="var(--primary-text)" size="1.25rem" />
            </StyledCloseContainer>
          </Button>
        </Box>
        <Box align="center" gap="1rem">
          {Array.isArray(customMessage) ? (
            <Box direction="column" gap="0.5rem">
              {customMessage.map((msg) => (
                <StyledText key={uniqid()} size="1.1rem">{msg}</StyledText>
              ))}
            </Box>
          ) : (
            <StyledText size="1.1rem">{customMessage}</StyledText>
          )}
        </Box>
      </Box>
    </StyledLayer>
  );
};

CustomNotification.propTypes = {
  customMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  closeCustomNotice: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { customMessage } = state.notice;
  return { customMessage };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeCustomNotice: closeCustomNoticeRequest }, dispatch);
}

CustomNotification.propTypes = {
  customMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  closeCustomNotice: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomNotification);
