import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import uniqid from 'uniqid';
import Case from 'case';

import {
  Box, Text, Image, Button,
} from 'grommet';

import { DropButton } from '@Components/Control';
import { buildDateLabel } from '@Helpers/util';
import { localAsset } from '@Helpers/asset';
import {
  renderTableHeader,
  NoDataPlaceholder,
  TableWrapper,
  SearchInput,
  SortDropdown,
  FilterDropdown,
} from '../SharedComponents';
import {
  StyledStatusBadge, StyledTooltip, StyledHoverText,
} from './StyledListSearches';
import { StyledSVG, StyledHoverContainer } from '../StyledSharedComponents';

const ResultsContainer = ({ small, children, ...rest }) => (
  <Box
    fill
    wrap={false}
    direction={small ? 'column' : 'row'}
    border={{
      color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
    }}
    align={small ? 'start' : 'center'}
    gap={small ? '0.75rem' : '0'}
    {...rest}
  >
    {children}
  </Box>
);

ResultsContainer.propTypes = {
  small: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const TruncatedText = ({
  small,
  rowData,
  uid,
  textColor,
  boxWidth,
  leftPad,
  verticalPad,
  noDataText,
  highlightColor,
  searchPriorityIcon,
}) => {
  const truncateText = (str) => ((str.length > 117) ? `${str.substring(0, 114)}...` : str);
  const displayText = rowData[uid] ? truncateText(rowData[uid]) : noDataText;
  const tooltipUid = uniqid();
  const priorityUid = uniqid();
  const isPrioritized = rowData.priority && rowData.priority === 'prioritized';

  return (
    <Box
      direction="row"
      gap="0.55rem"
      align="center"
      width={boxWidth}
      pad={{ left: isPrioritized ? '0' : leftPad, vertical: verticalPad }}
      margin={{ left: isPrioritized ? '-1rem' : '0', right: isPrioritized ? '1rem' : '0' }}
    >
      {isPrioritized && (
        <Box pad={{ top: small ? '0.2rem' : '0.1rem' }}>
          <StyledSVG
            src={searchPriorityIcon}
            width={small ? '1rem' : '0.8rem'}
            height={small ? '1rem' : '0.8rem'}
            title="Prioritized"
            data-for={`search-priority-tooltip-${priorityUid}`}
            data-tip="Prioritized"
          />
          {!small && (
            <StyledTooltip
              id={`search-priority-tooltip-${priorityUid}`}
              arrowColor={highlightColor}
              backgroundColor={highlightColor}
              place="bottom"
              effect="solid"
              delayShow={300}
            />
          )}
        </Box>
      )}
      <Text
        size={small ? '1.2rem' : '1rem'}
        color={textColor}
        weight={small ? 600 : 500}
        data-for={`${tooltipUid}-tooltip`}
        data-tip={rowData[uid]}
      >
        {displayText}
      </Text>
      {!small && rowData[uid] && rowData[uid].length > 117 && (
        <StyledTooltip
          id={`${tooltipUid}-tooltip`}
          arrowColor={highlightColor}
          backgroundColor={highlightColor}
          place="bottom"
          effect="solid"
          delayShow={300}
        />
      )}
    </Box>
  );
};

TruncatedText.propTypes = {
  small: PropTypes.bool.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string,
    title: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    status: PropTypes.string.isRequired,
    priority: PropTypes.string,
    resultCount: PropTypes.number,
    infringements: PropTypes.number,
    platforms: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  uid: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  boxWidth: PropTypes.string.isRequired,
  leftPad: PropTypes.string.isRequired,
  verticalPad: PropTypes.string.isRequired,
  noDataText: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  searchPriorityIcon: PropTypes.string.isRequired,
};

const SearchControlButtons = ({
  small,
  buttonOnly = false,
  isMediumScreen,
  searchId,
  dashboardPath,
  seeDetailsIcon,
  selectStatusColor,
  data = null,
  leftPad = null,
  boxWidth = null,
  highlightText,
  buttonHighlight,
  searchFeatures = null,
  submitSearchFeature = null,
}) => {
  const showFeatureOptions = searchFeatures?.length > 0;

  const renderControlMenu = () => (
    <Box align="center" justify="center">
      <DropButton
        formIcons
        overridePosition={{ bottom: 'top' }}
        withCloseDropContent={(onClose) => (
          <Box
            round="10px"
            pad="1rem"
            direction="column"
            gap={small ? '1rem' : '0.65rem'}
            border={{ side: 'all', style: 'solid', color: '#D0D0D0' }}
          >
            {searchFeatures.map((feature) => (
              <Button
                plain
                key={feature}
                onClick={() => {
                  submitSearchFeature(searchId, feature);
                  onClose();
                }}
              >
                <StyledHoverText weight={600} hoverColor={buttonHighlight}>
                  {`${Case.capital(feature)} search`}
                </StyledHoverText>
              </Button>
            ))}
          </Box>
        )}
      >
        {() => (
          <StyledHoverContainer hoverColor={buttonHighlight} align="center" justify="center" pad={{ top: small ? '0.25rem' : '0.45rem' }}>
            <StyledSVG
              src={localAsset('images/more-options-icon.svg')}
              width={small ? '1.8rem' : '1.5rem'}
              height={small ? '1.2rem' : '1rem'}
              title="Search control options"
              data-for={`search-control-tooltip-${searchId}`}
              data-tip="Search controls"
            />
            {!small && (
              <StyledTooltip
                id={`search-control-tooltip-${searchId}`}
                arrowColor={buttonHighlight}
                backgroundColor={buttonHighlight}
                place="bottom"
                effect="solid"
                delayShow={300}
              />
            )}
          </StyledHoverContainer>
        )}
      </DropButton>
    </Box>
  );

  if (buttonOnly) {
    return (
      <Box direction="row" gap="2.5rem">
        {showFeatureOptions && renderControlMenu()}
        <Button plain href={dashboardPath(searchId)}>
          <Box flex={false} width="1.6rem" height="1.6rem">
            <Image
              src={seeDetailsIcon}
              alt="See more details"
              fit="contain"
              width="1.6rem"
              height="1.6rem"
            />
          </Box>
        </Button>
      </Box>
    );
  }

  return (
    <Box width={boxWidth} direction="row" justify="start" align="center" pad={{ left: leftPad }}>
      <StyledStatusBadge background={selectStatusColor(data, 'background')}>
        <Text size="1rem" color={selectStatusColor(data, 'text')} weight={600}>
          {Case.capital(data)}
        </Text>
      </StyledStatusBadge>
      {!small && (
        <Box fill direction="row" justify="end" align="center" gap={isMediumScreen ? '1.5rem' : '3rem'} pad={{ right: '1rem' }}>
          {showFeatureOptions && renderControlMenu()}
          <Button plain href={dashboardPath(searchId)}>
            <Box wrap={false} direction="row" gap="0.5rem" align="center">
              {!isMediumScreen && (
                <Text size="1rem" color={highlightText}>
                  See details
                </Text>
              )}
              <Box flex={false} width="1rem" height="1rem">
                <Image
                  src={seeDetailsIcon}
                  alt="See more details"
                  fit="contain"
                  width="1rem"
                  height="1rem"
                />
              </Box>
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};

SearchControlButtons.propTypes = {
  small: PropTypes.bool.isRequired,
  buttonOnly: PropTypes.bool,
  isMediumScreen: PropTypes.bool.isRequired,
  dashboardPath: PropTypes.func.isRequired,
  searchId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  searchFeatures: PropTypes.arrayOf(PropTypes.string.isRequired),
  seeDetailsIcon: PropTypes.string.isRequired,
  selectStatusColor: PropTypes.func.isRequired,
  data: PropTypes.string,
  leftPad: PropTypes.string,
  boxWidth: PropTypes.string,
  highlightText: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  submitSearchFeature: PropTypes.func,
};

const SearchResultRow = ({
  small,
  isMediumScreen,
  rowData,
  statusColorMap,
  seeDetailsIcon,
  searchPriorityIcon,
  primaryText,
  highlightText,
  buttonHighlight,
  headerMap,
  dashboardPath,
  submitSearchFeature = null,
}) => {
  const selectStatusColor = (status, type) => {
    const currentStatus = statusColorMap[status];

    if (currentStatus) return currentStatus[type];

    return null;
  };

  const renderRowData = (uid) => {
    const currentHeader = _.find(headerMap, { uid });
    const noDataText = (uid === 'resultCount' || uid === 'infringements') ? '0' : 'N/A';
    const boxWidth = small ? 'auto' : currentHeader.size;
    const verticalPad = small ? '0' : '1.5rem';
    let leftPad = small ? '0' : '0.5rem';

    if (uid === 'resultCount' || uid === 'infringements') leftPad = small ? '0' : '2.5%';

    switch (uid) {
      case 'date':
        return (
          <Box key={uniqid()} width={boxWidth} pad={{ left: leftPad, vertical: verticalPad }}>
            <Text size="1rem" color={primaryText}>
              {buildDateLabel(rowData.startDate, rowData.endDate, true)}
            </Text>
          </Box>
        );

      case 'status':
        return (
          <SearchControlButtons
            key={uniqid()}
            small={small}
            isMediumScreen={isMediumScreen}
            searchId={rowData.id}
            dashboardPath={dashboardPath}
            seeDetailsIcon={seeDetailsIcon}
            selectStatusColor={selectStatusColor}
            data={rowData[uid]}
            searchFeatures={rowData.features}
            leftPad={leftPad}
            boxWidth={boxWidth}
            highlightText={highlightText}
            buttonHighlight={buttonHighlight}
            submitSearchFeature={submitSearchFeature}
          />
        );

      case 'title':
      case 'name':
        return (
          <TruncatedText
            key={uniqid()}
            small={small}
            rowData={rowData}
            uid={uid}
            textColor={primaryText}
            boxWidth={small ? '75%' : currentHeader.size}
            leftPad={leftPad}
            verticalPad={verticalPad}
            noDataText={noDataText}
            highlightColor={buttonHighlight}
            searchPriorityIcon={searchPriorityIcon}
          />
        );

      case 'platforms':
        return (
          <Box key={uniqid()} width={boxWidth} pad={{ left: leftPad, vertical: verticalPad }}>
            <Text size="1rem" color={primaryText} weight={400}>
              {rowData[uid] ? rowData[uid].join(', ') : noDataText}
            </Text>
          </Box>
        );

      default:
        return (
          <Box key={uniqid()} width={boxWidth} pad={{ left: leftPad, vertical: verticalPad }}>
            <Text size="1rem" color={primaryText} weight={400}>
              {rowData[uid] || noDataText}
            </Text>
          </Box>
        );
    }
  };

  if (small) {
    return (
      <ResultsContainer small pad={{ vertical: '1rem' }}>
        <Box width="100%" direction="row" justify="between">
          {renderRowData('name')}
          {renderRowData('status')}
        </Box>
        <Box width="100%" direction="row" gap="0.3rem">
          {renderRowData('resultCount')}
          Infringements
        </Box>
        <Box width="100%" direction="row" justify="between">
          {renderRowData('date')}
          <SearchControlButtons
            small
            buttonOnly
            key={uniqid()}
            isMediumScreen={isMediumScreen}
            searchId={rowData.id}
            dashboardPath={dashboardPath}
            searchFeatures={rowData.features}
            seeDetailsIcon={seeDetailsIcon}
            selectStatusColor={selectStatusColor}
            highlightText={highlightText}
            buttonHighlight={buttonHighlight}
            submitSearchFeature={submitSearchFeature}
          />
        </Box>
      </ResultsContainer>
    );
  }

  return (
    <ResultsContainer small={false}>
      {headerMap.map(({ uid }) => renderRowData(uid))}
    </ResultsContainer>
  );
};

SearchResultRow.propTypes = {
  small: PropTypes.bool.isRequired,
  isMediumScreen: PropTypes.bool.isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string,
    title: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    status: PropTypes.string.isRequired,
    priority: PropTypes.string,
    resultCount: PropTypes.number,
    infringements: PropTypes.number,
    platforms: PropTypes.arrayOf(PropTypes.string.isRequired),
    features: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  statusColorMap: PropTypes.shape({
    running: PropTypes.shape({
      text: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
    }).isRequired,
    completed: PropTypes.shape({
      text: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
    }).isRequired,
    paused: PropTypes.shape({
      text: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
    }).isRequired,
    scheduled: PropTypes.shape({
      text: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  headerMap: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  seeDetailsIcon: PropTypes.string.isRequired,
  searchPriorityIcon: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  highlightText: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  dashboardPath: PropTypes.func.isRequired,
  submitSearchFeature: PropTypes.func,
};

const TableControls = ({
  small,
  focusHighlight,
  buttonHighlight,
  selectButtonBg,
  primaryText,
  displayInputValue = null,
  searchQuery,
  handleFilter,
  handleSort,
  useFilters = null,
  listSearchesMeta = null,
}) => {
  if (listSearchesMeta) {
    return (
      <Box direction="row" gap="0.5rem">
        <SearchInput
          key="search"
          small={small}
          focusHighlight={focusHighlight}
          handleSearch={(uid, value, predicate, seperateValues, reset) => (
            handleFilter(uid, value, predicate, seperateValues, reset)
          )}
          initialValue={displayInputValue}
        />
        <FilterDropdown
          key={uniqid()}
          small={small}
          textColor={primaryText}
          focusHighlight={focusHighlight}
          buttonHighlight={buttonHighlight}
          selectButtonBg={selectButtonBg}
          selectedFilters={searchQuery.filters || null}
          handleFilter={(uid, value, predicate, seperateValues, reset) => (
            handleFilter(uid, value, predicate, seperateValues, reset)
          )}
          filterOptions={useFilters}
        />
        <SortDropdown
          textColor={primaryText}
          focusHighlight={focusHighlight}
          selectedSortOption={searchQuery.sort || null}
          handleSort={(sortValue, sortDirection) => handleSort(sortValue, sortDirection)}
          sortOptions={listSearchesMeta.sort.name.options}
          orderOptions={listSearchesMeta.sort.direction.options}
        />
      </Box>
    );
  }

  return null;
};

TableControls.propTypes = {
  small: PropTypes.bool.isRequired,
  focusHighlight: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  selectButtonBg: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  displayInputValue: PropTypes.string,
  searchQuery: PropTypes.shape({
    paging: PropTypes.shape({
      to: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
    }),
    sort: PropTypes.shape({
      name: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }),
    filters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      predicate: PropTypes.string.isRequired,
    })),
    page: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  useFilters: PropTypes.arrayOf(PropTypes.any.isRequired),
  listSearchesMeta: PropTypes.objectOf(PropTypes.any.isRequired),
};

const ListSearchesTable = ({
  small,
  loading,
  listSearchesData = null,
  listSearchesMeta = null,
  searchQuery,
  pageTitle,
  headerMap,
  handleQuery,
  handleSort = null,
  handleFilter = null,
  submitSearchFeature = null,
  dashboardPath,
  resultsPerPageOptions,
  currentListUid = null,
  authPagesConfig: {
    primaryText,
    focusHighlight,
    buttonHighlight,
    selectButtonBg,
    icons: {
      seeDetailsIcon,
      searchPriorityIcon,
    },
    statusColorMap,
  },
}) => {
  if (loading || !listSearchesData || (
    listSearchesData && listSearchesData.data?.searches?.length === 0
  )) {
    const showRefreshButton = listSearchesData
      && listSearchesData.data?.searches?.length === 0
      && searchQuery.filters;

    return (
      <NoDataPlaceholder
        noShadow
        noRound
        showRefreshButton={showRefreshButton}
        refreshQuery={() => handleFilter(null, null, null, false, true)}
        buttonHighlight={buttonHighlight}
        loading={loading || !listSearchesData}
        label="No results found for this category"
        textColor={primaryText}
        loaderColor={buttonHighlight}
      />
    );
  }

  const searchInputValue = searchQuery?.filters && _.find(searchQuery.filters, ['name', 'name']);
  let displayInputValue = '';
  const isMediumScreen = window.innerWidth <= 975;

  const showFilters = listSearchesMeta && listSearchesMeta.filters;
  let useFilters = null;

  if (showFilters) {
    useFilters = _.filter(listSearchesMeta.filters.options, (({ name }) => name !== 'name' && name !== 'status'));

    if (currentListUid === 'all') {
      useFilters = _.filter(listSearchesMeta.filters.options, (({ name }) => name !== 'name'));
    }
  }

  if (searchInputValue && searchInputValue.value) {
    displayInputValue = searchInputValue.value;
  }

  const currentPage = searchQuery?.paging?.to;
  const resultsPerPage = searchQuery?.paging?.size;

  const handlePagination = (parentKey, updateKey, val) => {
    handleQuery(parentKey, updateKey, val);
  };

  return (
    <TableWrapper
      small={small}
      header={pageTitle}
      textColor={primaryText}
      boxPad={{ horizontal: small ? '1.5rem' : '2rem', top: '1rem', bottom: small ? '1.5rem' : '2rem' }}
      totalResults={listSearchesData.data.totalCount}
      currentResults={listSearchesData.data.searches.length}
      selectedResultsCount={resultsPerPage}
      handleResultsPerPage={(val) => handlePagination('paging', 'size', val)}
      handlePage={(val) => handlePagination('paging', 'to', val)}
      resultsPerPageOptions={resultsPerPageOptions}
      isPaged={listSearchesData.data.totalCount > listSearchesData.data.searches.length}
      currentPage={currentPage}
      tableControls={(
        <TableControls
          small={small}
          focusHighlight={focusHighlight}
          buttonHighlight={buttonHighlight}
          selectButtonBg={selectButtonBg}
          primaryText={primaryText}
          displayInputValue={displayInputValue}
          searchQuery={searchQuery}
          handleFilter={handleFilter}
          handleSort={handleSort}
          useFilters={useFilters}
          listSearchesMeta={listSearchesMeta}
        />
      )}
    >
      <Box>
        {!small && (
          <Box
            fill="horizontal"
            wrap={false}
            direction="row"
            border={{
              color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
            }}
          >
            {headerMap.map(({ uid, display, size }) => (
              <Box key={uid} width={size} pad={{ horizontal: '0.5rem', top: '1rem', bottom: '0.5rem' }}>
                {renderTableHeader(display, small)}
              </Box>
            ))}
          </Box>
        )}
        <Box fill="horizontal" direction="column">
          {listSearchesData.data.searches.map((rowData) => (
            <SearchResultRow
              key={uniqid()}
              small={small}
              rowData={rowData}
              statusColorMap={statusColorMap}
              seeDetailsIcon={seeDetailsIcon}
              searchPriorityIcon={searchPriorityIcon}
              primaryText={primaryText}
              highlightText={focusHighlight}
              buttonHighlight={buttonHighlight}
              headerMap={headerMap}
              isMediumScreen={isMediumScreen}
              dashboardPath={dashboardPath}
              submitSearchFeature={submitSearchFeature}
            />
          ))}
        </Box>
      </Box>
    </TableWrapper>
  );
};

ListSearchesTable.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  listSearchesData: PropTypes.shape({
    data: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      searches: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        name: PropTypes.string,
        title: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        status: PropTypes.string.isRequired,
        priority: PropTypes.string,
        resultCount: PropTypes.number,
        infringements: PropTypes.number,
        platforms: PropTypes.arrayOf(PropTypes.string.isRequired),
        features: PropTypes.arrayOf(PropTypes.string.isRequired),
      })).isRequired,
    }),
  }),
  listSearchesMeta: PropTypes.shape({
    sort: PropTypes.shape({
      name: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
      direction: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
    }).isRequired,
    filters: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        formatted: PropTypes.string.isRequired,
        validValues: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired),
        validPredicates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }).isRequired).isRequired,
    }).isRequired,
  }),
  searchQuery: PropTypes.shape({
    paging: PropTypes.shape({
      to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
    sort: PropTypes.shape({
      name: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }),
    filters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      predicate: PropTypes.string.isRequired,
    })),
    page: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    icons: PropTypes.shape({
      seeDetailsIcon: PropTypes.string.isRequired,
      searchPriorityIcon: PropTypes.string.isRequired,
    }).isRequired,
    statusColorMap: PropTypes.shape({
      running: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      completed: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      paused: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      scheduled: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pageTitle: PropTypes.string.isRequired,
  currentListUid: PropTypes.string,
  headerMap: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  handleQuery: PropTypes.func.isRequired,
  handleSort: PropTypes.func,
  handleFilter: PropTypes.func,
  submitSearchFeature: PropTypes.func,
  dashboardPath: PropTypes.func.isRequired,
  resultsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

export default ListSearchesTable;
