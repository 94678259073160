import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Text, Button, Anchor, Image,
} from 'grommet';
import { Checkmark } from 'grommet-icons';

import { RadioToggleButton } from '@Components/Control';
import { localAsset } from '@Helpers/asset';
import {
  ProductFormCalendarInput,
  ProductFormSingleTextInput,
  ProductFormDropdownSelect,
  ProductFormCheckBoxInput,
} from '@Components/Partial/Product/NewSearch';

import {
  renderTableHeader,
} from '../SharedComponents';
import {
  StyledSVG,
  StyledTooltip,
  StyledIconButton,
  StyledSelectButton,
  StyledSelectionContainer,
  StyledCyclopsContainer,
} from './StyledCyclopsComponents';


export const IconButton = ({
  icon, label, onClick, color, bgColor, disabled = false,
}) => (
  <StyledIconButton
    plain
    icon={icon}
    label={label}
    onClick={onClick}
    color={color}
    bgColor={bgColor}
    disabled={disabled}
  />
);

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export const SelectButton = ({
  label, onClick, color, hoverColor, bgColor, selected = false, disabled = false,
}) => (
  <StyledSelectButton
    plain
    selected={selected}
    label={label}
    onClick={onClick}
    color={color}
    hoverColor={hoverColor}
    bgColor={bgColor}
    disabled={disabled}
  />
);

SelectButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const TruncatedText = ({
  small,
  value,
  textColor,
  noDataText,
  highlightColor,
  truncateLength = 70,
}) => {
  const subLength = truncateLength - 3;
  const truncateText = (str) => ((str.length > truncateLength) ? `${str.substring(0, subLength)}...` : str);
  const displayText = value ? truncateText(value) : noDataText;
  const tooltipUid = uniqid();

  return (
    <Box direction="row" gap="0.55rem" align="center">
      <Text
        size="1rem"
        color={textColor}
        data-for={`${tooltipUid}-tooltip`}
        data-tip={value}
      >
        {displayText}
      </Text>
      {!small && value?.length > truncateLength && (
        <StyledTooltip
          id={`${tooltipUid}-tooltip`}
          arrowColor={highlightColor}
          backgroundColor={highlightColor}
          place="bottom"
          effect="solid"
          delayShow={300}
        />
      )}
    </Box>
  );
};

TruncatedText.propTypes = {
  small: PropTypes.bool.isRequired,
  value: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  noDataText: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  truncateLength: PropTypes.number,
};

export const FormInputSelector = ({
  small,
  label,
  renderKey,
  inputKey,
  inputType,
  value = null,
  placeholder = null,
  handleFormValues,
  authPagesConfig,
  today = null,
  dropdownOptions = null,
}) => {
  const {
    darkModeColors: {
      primaryDarkBg, primaryText, secondaryText,
    },
  } = authPagesConfig;

  const selectInput = () => {
    switch (renderKey) {
      case 'text':
        return (
          <ProductFormSingleTextInput
            small={small}
            key={inputKey}
            type={inputType ?? 'text'}
            bgColor={primaryDarkBg}
            borderColor="#394658"
            placeholder={placeholder}
            name={inputKey}
            focusHighlight={secondaryText}
            value={value ?? ''}
            handleFormValues={(val) => handleFormValues(inputKey, val, inputType === 'number')}
          />
        );

      case 'calendar':
        return (
          <ProductFormCalendarInput
            timeSelectOverride
            darkmode
            showCloseButton
            key={inputKey}
            authPagesConfig={authPagesConfig}
            inputConfig={null}
            small={small}
            value={value ?? undefined}
            handleFormValues={(val) => handleFormValues(inputKey, val)}
            today={today}
            minStartDate={null}
          />
        );

      case 'dropdown':
        return (
          <ProductFormDropdownSelect
            selectable
            darkmode
            keepLabel
            key={inputKey}
            authPagesConfig={authPagesConfig}
            small={small}
            value={value}
            handleFormValues={(val) => handleFormValues(inputKey, val)}
            simpleOptions={dropdownOptions}
          />
        );

      case 'checkbox':
        return (
          <ProductFormCheckBoxInput
            small={small}
            key={inputKey}
            bgColor={primaryDarkBg}
            borderColor="#394658"
            checkmarkColor={primaryText}
            name={inputKey}
            focusHighlight={secondaryText}
            value={value}
            handleFormValues={(val) => handleFormValues(inputKey, val)}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Box pad={{ bottom: '1rem' }} direction={small ? 'column' : 'row'} justify="between">
      <Text size="1rem" color={primaryText}>{label}</Text>
      <Box width={small ? '100%' : '60%'} pad={{ top: small ? '0.5rem' : '0' }}>
        {selectInput()}
      </Box>
    </Box>
  );
};

FormInputSelector.propTypes = {
  small: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  renderKey: PropTypes.string.isRequired,
  inputKey: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  handleFormValues: PropTypes.func.isRequired,
  authPagesConfig: PropTypes.object.isRequired,
  today: PropTypes.instanceOf(Date),
  dropdownOptions: PropTypes.arrayOf(PropTypes.string),
};

export const FilesTable = ({
  small, headerColor, textColor, filesData, handleUpload,
}) => {
  const nameRowWidth = small ? '40%' : '25%';
  const sizeRowWidth = '20%';
  const dateRowWidth = small ? '40%' : '25%';

  const headerMap = [
    { uid: 'fileName', display: 'Files', size: nameRowWidth },
    { uid: 'fileSize', display: 'Size', size: sizeRowWidth },
    { uid: 'uploadDate', display: 'Upload Date', size: dateRowWidth },
  ];

  const rowPad = { horizontal: '0.5rem', top: '1rem', bottom: '0.5rem' };

  const renderControlButtons = (width) => (
    <Box
      width={width}
      direction="row"
      justify="end"
      gap={small ? '1.75rem' : '1rem'}
      pad={{ right: small ? '0.5rem' : '4.5rem', bottom: small ? '0.5rem' : '0' }}
    >
      <Button plain onClick={() => null}>
        <StyledSVG
          src={localAsset('images/download-file-icon.svg')}
          height={small ? '1.25rem' : '1.1rem'}
          width={small ? '1.25rem' : '1.1rem'}
          $fillColor={headerColor}
        />
      </Button>
      <Button plain onClick={() => null}>
        <StyledSVG
          src={localAsset('images/delete-content-icon.svg')}
          height={small ? '1.25rem' : '1.1rem'}
          width={small ? '1.25rem' : '1.1rem'}
          $fillColor={headerColor}
        />
      </Button>
    </Box>
  );

  return (
    <Box direction="column">
      <Box
        fill="horizontal"
        wrap={false}
        direction="row"
        border={{
          color: '#E5E9EB', size: '2px', style: 'solid', side: 'bottom',
        }}
      >
        {headerMap.map(({ uid, display, size }) => (
          <Box key={uid} width={size} pad={{ horizontal: '0.5rem', top: '1rem', bottom: '0.5rem' }}>
            {renderTableHeader(display, small, headerColor)}
          </Box>
        ))}
        {!small && (
          <Box align="end" width="30%">
            <IconButton
              icon={<StyledSVG src={localAsset('images/upload-file-icon.svg')} height="0.85rem" width="0.85rem" $fillColor="#3C3C3C" />}
              label="Upload file"
              onClick={handleUpload}
              color="#3C3C3C"
              bgColor={headerColor}
            />
          </Box>
        )}
      </Box>
      {(!filesData || filesData?.length === 0) && (
        <Box pad={{ left: '0.5rem' }}>
          <Text size="1rem" color={headerColor} margin={{ top: '1rem' }}>
            No files uploaded
          </Text>
        </Box>
      )}
      {filesData?.length > 0 && filesData.map(({
        fileName, fileSize, fileUid, uploadDate,
      }, i) => (
        <Box
          direction="column"
          key={fileUid}
          border={i !== filesData.length - 1 && {
            color: '#394658', size: '1px', style: 'solid', side: 'bottom',
          }}
        >
          <Box fill="horizontal" wrap={false} direction="row">
            <Box width={nameRowWidth} pad={rowPad}>
              <Text size="1rem" color={textColor}>{fileName}</Text>
            </Box>
            <Box width={sizeRowWidth} pad={rowPad}>
              <Text size="1rem" color={textColor}>{fileSize}</Text>
            </Box>
            <Box width={dateRowWidth} pad={rowPad}>
              <Text size="1rem" color={textColor}>{uploadDate}</Text>
            </Box>
            {!small && renderControlButtons('30%')}
          </Box>
          {small && renderControlButtons('100%')}
        </Box>
      ))}
    </Box>
  );
};

FilesTable.propTypes = {
  small: PropTypes.bool.isRequired,
  headerColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  filesData: PropTypes.arrayOf(PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    fileUid: PropTypes.number.isRequired,
    fileSize: PropTypes.string.isRequired,
    uploadDate: PropTypes.string.isRequired,
  })).isRequired,
  handleUpload: PropTypes.func.isRequired,
};

export const StreamDataTable = ({
  small, tableTitle, textColor, highlightColor, dividerColor, containerBg, streamData,
}) => {
  const headerMap = [
    { uid: 'streamUrl', display: 'Infringing URL', size: '30%' },
    { uid: 'detectionTime', display: 'Detection Time', size: '30%' },
    { uid: 'viewers', display: 'Est. Viewers', size: '25%' },
    { uid: 'actions', display: 'Actions', size: '15%' },
  ];

  const rowPad = { horizontal: '0.5rem', vertical: '1rem' };

  const renderActionButton = (imagePath, color) => (
    <Button plain onClick={() => null}>
      <Box
        round="5px"
        background={color}
        align="center"
        justify="center"
        width={small ? '2.5rem' : '2rem'}
        height={small ? '2.5rem' : '2rem'}
        pad={{ top: '0.25rem' }}
      >
        <StyledSVG
          src={localAsset(imagePath)}
          height={small ? '1.25rem' : '1.1rem'}
          width={small ? '1.25rem' : '1.1rem'}
          $fillColor="white"
        />
      </Box>
    </Button>
  );

  return (
    <Box direction="column" background={containerBg} pad="2rem">
      <Box
        fill="horizontal"
        border={{
          color: dividerColor, size: '2px', style: 'solid', side: 'bottom',
        }}
        pad={{ bottom: '1rem' }}
      >
        <Text size="1.25rem" weight={600} color={textColor} textAlign="start">
          {tableTitle}
        </Text>
      </Box>
      {!small && (
        <Box
          fill="horizontal"
          wrap={false}
          direction="row"
          border={{
            color: dividerColor, size: '1px', style: 'solid', side: 'bottom',
          }}
        >
          {headerMap.map(({ uid, display, size }) => (
            <Box key={uid} width={size} pad={{ horizontal: '0.5rem', vertical: '1rem' }}>
              {renderTableHeader(display, small, highlightColor)}
            </Box>
          ))}
        </Box>
      )}
      {(!streamData || streamData?.length === 0) && (
        <Box pad={{ left: '0.5rem' }}>
          <Text size="1rem" color={highlightColor} margin={{ top: '1rem' }}>
            Searching for livestreams
          </Text>
        </Box>
      )}
      {streamData?.length > 0 && streamData.map(({
        streamUrl, detectionTime, viewers,
      }) => (
        <Box
          direction="column"
          key={uniqid()}
          border={{
            color: dividerColor, size: '1px', style: 'solid', side: 'bottom',
          }}
          pad={{ vertical: small ? '1rem' : '0' }}
        >
          <Box fill="horizontal" wrap={false} direction={small ? 'column' : 'row'} gap={small ? '0.5rem' : '0'}>
            <Box width={small ? '100%' : '30%'} pad={small ? '0' : rowPad} direction="row" justify="between" align="center">
              <Anchor href={streamUrl} target="_blank">
                <Text size="1rem" color={textColor}>{streamUrl}</Text>
              </Anchor>
              {small && (
                <Box
                  direction="row"
                  align="center"
                  gap="1rem"
                  pad={{ left: '0.5rem' }}
                >
                  {renderActionButton('images/power-symbol-icon.svg', '#EC2C2C')}
                  {renderActionButton('images/replace-content-icon.svg', '#14191F')}
                </Box>
              )}
            </Box>
            <Box width={small ? '100%' : '30%'} pad={small ? '0' : rowPad}>
              <Text size="1rem" color={textColor}>{detectionTime}</Text>
            </Box>
            <Box width={small ? '100%' : '25%'} pad={small ? '0' : rowPad}>
              <Text size="1rem" color={textColor}>{viewers}</Text>
            </Box>
            {!small && (
              <Box
                width="15%"
                direction="row"
                align="center"
                gap="1.5rem"
                pad={{ left: '0.5rem' }}
              >
                {renderActionButton('images/power-symbol-icon.svg', '#EC2C2C')}
                {renderActionButton('images/replace-content-icon.svg', '#14191F')}
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

StreamDataTable.propTypes = {
  small: PropTypes.bool.isRequired,
  tableTitle: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  dividerColor: PropTypes.string.isRequired,
  containerBg: PropTypes.string.isRequired,
  streamData: PropTypes.arrayOf(PropTypes.shape({
    streamUrl: PropTypes.string.isRequired,
    detectionTime: PropTypes.string.isRequired,
    viewers: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

export const SelectionCard = ({
  small,
  title,
  description,
  primaryText,
  highlightColor,
  containerBg,
  imageUrl,
  selected,
  fileUid,
  handleSelect,
  removable,
  handleRemove,
}) => (
  <Box direction="column" gap="0.5rem">
    <StyledSelectionContainer
      onClick={() => handleSelect(fileUid)}
      selected={selected}
      highlightColor={highlightColor}
    >
      <Box
        border={{ color: 'white', size: '2px' }}
        background={selected ? '#038BFC' : containerBg}
        width={small ? '2rem' : '1.25rem'}
        height={small ? '2rem' : '1.25rem'}
        justify="center"
        align="center"
        round="50%"
        className="checkMark"
      >
        {selected && <Checkmark color="white" size={small ? '1.25rem' : '0.8rem'} />}
      </Box>
      <Box background={containerBg} direction="column" gap="0.5rem" width="100%" height={small ? '17rem' : '12.5rem'}>
        <Box flex round="10px" pad={{ bottom: '0.5rem' }}>
          <Image src={imageUrl} fit="cover" style={{ borderRadius: '10px' }} />
        </Box>
      </Box>
    </StyledSelectionContainer>
    <Box direction="row" justify="between">
      <Text size="1.1rem" weight={500} color={primaryText}>{title}</Text>
      {removable && (
        <Button plain onClick={handleRemove(fileUid)}>
          <StyledSVG
            src={localAsset('images/delete-content-icon.svg')}
            height={small ? '1.25rem' : '1rem'}
            width={small ? '1.25rem' : '1rem'}
            $fillColor={highlightColor}
          />
        </Button>
      )}
    </Box>
    <Box width={small ? '100%' : '65%'}>
      <Text size="0.9rem" weight={400} color={primaryText}>{description}</Text>
    </Box>
  </Box>
);

SelectionCard.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fileUid: PropTypes.number.isRequired,
  primaryText: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  containerBg: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  removable: PropTypes.bool,
  handleRemove: PropTypes.func.isRequired,
};

export const FeatureContainer = ({
  small,
  title,
  description,
  primaryText,
  containerBg,
  children,
  toggleValue = null,
}) => (
  <StyledCyclopsContainer background={containerBg} direction="column" gap="1rem" width={small ? '100%' : '85%'}>
    <Box direction="row" justify="between" align="center">
      <Text size="1.1rem" weight={500} color={primaryText}>{title}</Text>
      {toggleValue !== null && (
        <RadioToggleButton
          key={uniqid()}
          inactiveBg="#D9D9D9"
          activeBg="#004FFE"
          toggleBg="white"
          initialValue={toggleValue}
        />
      )}
    </Box>
    <Box width={small ? '100%' : '65%'}>
      <Text size="0.9rem" weight={400} color={primaryText}>{description}</Text>
    </Box>
    {children}
  </StyledCyclopsContainer>
);

FeatureContainer.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  containerBg: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  toggleValue: PropTypes.bool,
};
