import {
  CYCLOPS_ANALYTICS_DATA_LOADED,
  CYCLOPS_ANALYTICS_DATA_CLEAR,
} from '../../actionTypes';

import * as api from '../../apiHelper';
import {
  setFetchLoaderLoading,
  setFetchLoaderReset,
} from '../../fetchLoaderActions';
import { arriveNotice } from '../../noticeActions';
import { setFaviconLoading, setFaviconDefault } from '../../faviconActions';


// Reducer corresponding actions
function cyclopsAnalyticsClear() {
  return { type: CYCLOPS_ANALYTICS_DATA_CLEAR };
}

function cyclopsAnalyticsLoaded(analyticsData) {
  return {
    type: CYCLOPS_ANALYTICS_DATA_LOADED,
    payload: { analyticsData },
  };
}

// API
async function getAnalyticsData(args, token) {
  const response = await api.nestedParamsGet('cyclops/analytics/aggregated_stats', args, token, 'v3');
  return response.json();
}

// Helper
async function fetchAnalyticsData(dispatch, args, token) {
  try {
    const response = await getAnalyticsData(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsAnalyticsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
export function clearCyclopsAnalyticsRequest() {
  return async (dispatch) => {
    dispatch(cyclopsAnalyticsClear());
  };
}

export function initiateCyclopsAnalyticsRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    fetchAnalyticsData(dispatch, args, token);
  };
}
