import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Box, Text, Button,
} from 'grommet';
import { ReactSVG } from 'react-svg';
import { localAsset } from '@Helpers/asset';


const StyledHoverButton = styled(Button)`
  border-radius: 5px;
  padding: 0.25rem 0.6rem;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
    
    span {
      color: ${({ hoverTextColor, textColor }) => (hoverTextColor ?? textColor)};
    }

    svg {
      fill: ${({ hoverTextColor, textColor }) => (hoverTextColor ?? textColor)};

      path {
        fill: ${({ hoverTextColor, textColor }) => (hoverTextColor ?? textColor)};
      }
    }
  }
`;

const StyledSVG = styled(ReactSVG)`
  svg {
    height: ${({ height }) => height};
    width:${({ width }) => width};
    fill: ${({ $fillColor }) => $fillColor};
    path {
      fill: ${({ $fillColor }) => $fillColor};
    }
  }
`;

const ActionButton = ({
  small,
  label = null,
  iconPath,
  textColor,
  hoverColor,
  hoverTextColor,
  actionHandler,
  href = null,
  buttonName = null,
}) => (
  <StyledHoverButton
    plain
    onClick={() => actionHandler()}
    href={href}
    hoverColor={hoverColor}
    textColor={textColor}
    hoverTextColor={hoverTextColor}
    a11yTitle={buttonName ?? label}
  >
    <Box direction="row" gap="0.5rem" align="center">
      <StyledSVG
        src={localAsset(iconPath)}
        height={small ? '1.1rem' : '1rem'}
        width={small ? '1.1rem' : '1rem'}
        style={{ paddingTop: '0.2rem' }}
        $fillColor={textColor}
      />
      {label && <Text size="1rem" color={textColor}>{label}</Text>}
    </Box>
  </StyledHoverButton>
);

ActionButton.propTypes = {
  small: PropTypes.bool.isRequired,
  label: PropTypes.string,
  iconPath: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  hoverTextColor: PropTypes.string,
  hoverColor: PropTypes.string.isRequired,
  actionHandler: PropTypes.func.isRequired,
  href: PropTypes.string,
  buttonName: PropTypes.string,
};

export default ActionButton;
