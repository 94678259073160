import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box, Text, Button,
} from 'grommet';

import {
  Seo, AppButton, Spinning,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { CurrentDateContext } from '@Components/Context';
import { EventsListDemoContentPartial, LivestreamsTablePartial } from '@Components/Partial/Product/Cyclops';
import { paths } from '@Components/configs';
import { localAsset } from '@Helpers/asset';
import {
  initiateCyclopsLivestreamsRequest,
  clearCyclopsLivestreamsRequest,
  submitNewCyclopsLivestreamRequest,
  editCyclopsLivestreamRequest,
  deleteCyclopsLivestreamRequest,
  startOrStopCyclopsLivestreamRequest,
} from '@Actions';

import { FormInputSelector } from '../../../Partial/Product/Cyclops/CyclopsComponents';
import {
  StyledCyclopsHeading,
  StyledSVG,
} from '../../../Partial/Product/Cyclops/StyledCyclopsComponents';


const CyclopsEventsListPage = ({
  small,
  demoOnly,
  loading,
  submitLoading,
  mixpanel,
  location,
  fetchLivestreams,
  clearLivestreams,
  submitNewLivestream,
  editLivestream,
  deleteLivestream,
  startOrStopLivestream,
  organizationLivestreams = null,
  activeOrganization = null,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyDemoData = null,
}) => {
  const { status } = useParams();
  const navigate = useNavigate();
  const { today } = React.useContext(CurrentDateContext);
  const formattedDate = today.toLocaleDateString('en-US', {
    month: 'short', day: '2-digit', year: 'numeric',
  });
  const [showLivestreamForm, setShowLivestreamForm] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [activeEventId, setActiveEventId] = React.useState(null);
  /* eslint-disable camelcase */
  const [formValues, setFormValues] = React.useState({
    inputValues: {
      content_owner_id: activeOrganization?.uid,
      name: '',
      start_date_time: undefined,
      end_date_time: undefined,
      max_video_height: '',
      max_video_width: '',
      frames_per_second_numerator: '',
      frames_per_second_denominator: '',
      url_or_cidrs: '',
      encoder_outbound_protocol: null,
      client_media_id: '',
      current_origin_manifest_path: '',
      current_host_video_base_folder: '',
      protocol_config: null,
      expected_viewership: null,
      backup_origin_url: '',
    },
  });

  React.useEffect(() => {
    setShowLivestreamForm(false);
    setIsEditing(false);
    setActiveEventId(null);
  }, [status]);

  React.useEffect(() => {
    if (!demoOnly && !activeOrganization) navigate(paths.cyclopsOrganizations);
    if (!demoOnly && activeOrganization) {
      fetchLivestreams({ content_owner_id: activeOrganization.uid });
    }

    return () => {
      if (!demoOnly) clearLivestreams();
    };
  }, [activeOrganization]);

  const handleFormValues = (key, value, isInt = false) => {
    const useValue = isInt ? parseInt(value, 10) : value;

    setFormValues((prevState) => ({
      ...prevState,
      inputValues: {
        ...prevState.inputValues,
        [key]: useValue,
      },
    }));
  };

  const handleResetForm = () => {
    setFormValues({
      inputValues: {
        content_owner_id: activeOrganization?.uid,
        name: '',
        start_date_time: undefined,
        end_date_time: undefined,
        max_video_height: '',
        max_video_width: '',
        frames_per_second_numerator: '',
        frames_per_second_denominator: '',
        url_or_cidrs: '',
        encoder_outbound_protocol: null,
        client_media_id: '',
        current_origin_manifest_path: '',
        current_host_video_base_folder: '',
        protocol_config: null,
        expected_viewership: null,
        backup_origin_url: '',
      },
    });
  };

  const handleEditLivestream = (streamId) => {
    const livestream = organizationLivestreams.find(({ Id }) => Id === streamId);
    setFormValues({
      inputValues: {
        Id: livestream.Id,
        content_owner_id: activeOrganization?.uid,
        name: livestream.name,
        start_date_time: livestream.startDateTime,
        end_date_time: livestream.endDateTime,
        max_video_height: livestream.maxVideoHeight,
        max_video_width: livestream.maxVideoWidth,
        frames_per_second_numerator: livestream.framesPerSecondNumerator,
        frames_per_second_denominator: livestream.framesPerSecondDenominator,
        url_or_cidrs: livestream.urlOrCidrs,
        encoder_outbound_protocol: livestream.encoderOutboundProtocol,
        current_origin_manifest_path: livestream.currentOriginManifestPath,
        current_host_video_base_folder: livestream.currentHostVideoBaseFolder,
        client_media_id: livestream.clientMediaId,
        protocol_config: livestream.protocolConfig,
        expected_viewership: livestream.expectedViewership,
        backup_origin_url: livestream.backupOriginUrl,
      },
    });
    setActiveEventId(streamId);
    setIsEditing(true);
    setShowLivestreamForm(true);
  };

  const handleDeleteLivestream = (streamId) => {
    deleteLivestream({ Id: streamId }, paths.cyclopsEventsList.replace(':status', status));
  };

  const handleStartOrStopLivestream = (streamId, val) => {
    startOrStopLivestream({ Id: streamId, start: val }, paths.cyclopsEventsList.replace(':status', status));
  };

  const submitFormValues = () => {
    if (isEditing) {
      editLivestream(formValues.inputValues, paths.cyclopsEventsList.replace(':status', status));
    } else {
      submitNewLivestream(formValues.inputValues, paths.cyclopsEventsList.replace(':status', status));
    }
  };

  const {
    darkModeColors: {
      primaryDarkBg, containerBg, primaryText, secondaryText, menuButtonHighlight,
    },
    cyclopsStatusDisplayMap,
  } = authPagesConfig;

  let pageTitle = status === 'live' ? 'Live Events' : 'Past Events';

  if (!demoOnly && activeOrganization) {
    pageTitle = status === 'live' ? `${activeOrganization.name} - Live Events Schedule` : `${activeOrganization.name} - Past Events Schedule`;
  }

  if (!demoOnly && showLivestreamForm && !isEditing) pageTitle = 'Add a New Live Stream Event';

  if (!demoOnly && showLivestreamForm && isEditing) pageTitle = 'Edit Event CDN Configuration';

  const buttonTitle = showLivestreamForm ? 'Go Back' : 'Add Event';
  const requiredKeys = Object.keys(formValues.inputValues).filter((key) => (
    key !== 'protocol_config' && key !== 'client_media_id' && key !== 'backup_origin_url'));
  const submitDisabled = requiredKeys.some((key) => formValues.inputValues[key] === '' || formValues.inputValues[key] === null);

  const urlCidrMap = {
    RTMP: 'CIDRs',
    rtmp: 'CIDRs',
    HLS: 'URLs',
    hls: 'URLs',
    RTP: 'CIDRs',
    rtp: 'CIDRs',
    SRT: 'URLs',
    srt: 'URLs',
    RIST: 'CIDRs',
    rist: 'CIDRs',
  };

  const renderPageContent = (showForm) => {
    if (demoOnly) {
      return (
        <EventsListDemoContentPartial
          small={small}
          status={status}
          authPagesConfig={authPagesConfig}
          companyDemoData={companyDemoData}
        />
      );
    }

    if (showForm) {
      const sourceName = formValues.inputValues.encoder_outbound_protocol
        && urlCidrMap[formValues.inputValues.encoder_outbound_protocol];

      if (submitLoading) {
        return (
          <Box align="center" justify="center" pad={{ right: small ? '0' : '15%', top: isEditing ? '0' : '1.5rem' }}>
            <Spinning size="large" color={menuButtonHighlight} />
          </Box>
        );
      }

      return (
        <Box width="100%" justify="start" pad={{ right: small ? '0' : '15%', top: isEditing ? '0' : '1.5rem' }}>
          {isEditing && (
            <Box direction="column" pad={{ bottom: '1.5rem' }}>
              <Button plain onClick={() => handleDeleteLivestream(activeEventId)}>
                <Box direction="row" gap="0.5rem" align="center">
                  <StyledSVG
                    src={localAsset('images/delete-content-icon.svg')}
                    height={small ? '1.1rem' : '1rem'}
                    width={small ? '1.1rem' : '1rem'}
                    style={{ paddingTop: '0.15rem' }}
                    $fillColor={secondaryText}
                  />
                  <Text size="1rem" color={secondaryText}>Delete Event</Text>
                </Box>
              </Button>
            </Box>
          )}
          <FormInputSelector
            small={small}
            label="Name"
            renderKey="text"
            inputKey="name"
            inputType="text"
            value={formValues.inputValues.name}
            placeholder="Name for this event"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          {!isEditing && (
            <Box direction="column">
              <FormInputSelector
                small={small}
                label="Start date and time"
                renderKey="calendar"
                inputKey="start_date_time"
                value={formValues.inputValues.start_date_time}
                handleFormValues={handleFormValues}
                authPagesConfig={authPagesConfig}
                today={today}
              />
              <FormInputSelector
                small={small}
                label="End date and time"
                renderKey="calendar"
                inputKey="end_date_time"
                value={formValues.inputValues.end_date_time}
                handleFormValues={handleFormValues}
                authPagesConfig={authPagesConfig}
                today={today}
              />
              <FormInputSelector
                small={small}
                label="Max video height"
                renderKey="text"
                inputKey="max_video_height"
                inputType="number"
                value={formValues.inputValues.max_video_height}
                placeholder="Maximum height of the video in pixels"
                handleFormValues={handleFormValues}
                authPagesConfig={authPagesConfig}
              />
              <FormInputSelector
                small={small}
                label="Max video width"
                renderKey="text"
                inputKey="max_video_width"
                inputType="number"
                value={formValues.inputValues.max_video_width}
                placeholder="Maximum width of the video in pixels"
                handleFormValues={handleFormValues}
                authPagesConfig={authPagesConfig}
              />
              <FormInputSelector
                small={small}
                label="Encoder outbound protocol"
                renderKey="dropdown"
                inputKey="encoder_outbound_protocol"
                value={formValues.inputValues.encoder_outbound_protocol}
                placeholder="Maximum width of the video in pixels"
                handleFormValues={handleFormValues}
                authPagesConfig={authPagesConfig}
                dropdownOptions={['RTMP', 'HLS', 'RTP', 'SRT', 'RIST']}
              />
              {formValues.inputValues.encoder_outbound_protocol && (
                <FormInputSelector
                  small={small}
                  label={`Source ${sourceName}`}
                  renderKey="text"
                  inputKey="url_or_cidrs"
                  inputType="text"
                  value={formValues.inputValues.url_or_cidrs}
                  placeholder={`Enter ${sourceName} separated by commas`}
                  handleFormValues={handleFormValues}
                  authPagesConfig={authPagesConfig}
                />
              )}
              <FormInputSelector
                small={small}
                label="Expected viewership"
                renderKey="text"
                inputKey="expected_viewership"
                inputType="number"
                value={formValues.inputValues.expected_viewership}
                placeholder="Expected number of viewers for this event"
                handleFormValues={handleFormValues}
                authPagesConfig={authPagesConfig}
              />
            </Box>
          )}
          <FormInputSelector
            small={small}
            label="Frames per second numerator"
            renderKey="text"
            inputKey="frames_per_second_numerator"
            inputType="number"
            value={formValues.inputValues.frames_per_second_numerator}
            placeholder="Numerator value for the frames per second (FPS) of this stream"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Frames per second denominator"
            renderKey="text"
            inputKey="frames_per_second_denominator"
            inputType="number"
            value={formValues.inputValues.frames_per_second_denominator}
            placeholder="Denominator value for the frames per second (FPS) of this stream"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Origin manifest path"
            renderKey="text"
            inputKey="current_origin_manifest_path"
            inputType="text"
            value={formValues.inputValues.current_origin_manifest_path}
            placeholder="Folder location for the current origin manifest"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Host video base folder"
            renderKey="text"
            inputKey="current_host_video_base_folder"
            inputType="text"
            value={formValues.inputValues.current_host_video_base_folder}
            placeholder="Folder location for the current host video"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Media ID"
            renderKey="text"
            inputKey="client_media_id"
            inputType="text"
            value={formValues.inputValues.client_media_id}
            placeholder="Internal live stream media ID"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Backup origin URL"
            renderKey="text"
            inputKey="backup_origin_url"
            inputType="text"
            value={formValues.inputValues.backup_origin_url}
            placeholder="URL for the backup origin"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <Box pad={{ bottom: '1rem' }} justify="center" align="end">
            <AppButton
              overrideHover
              disabled={submitDisabled}
              level="dynamicLarge"
              color="white"
              width="7.5rem"
              bgColor={authPagesConfig.buttonHighlight}
              onClick={() => submitFormValues()}
              fontWeight={600}
              label="Save"
            />
          </Box>
        </Box>
      );
    }
    /* eslint-enable camelcase */

    let headerMap = [
      { uid: 'name', display: 'Name', size: '30%' },
      { uid: 'startDateTime', display: 'Start Date & Time', size: '25%' },
      { uid: 'status', display: 'Status', size: '20%' },
      {
        uid: 'actions', display: 'Actions', size: '25%', actions: ['details', 'edit', 'delete'],
      },
    ];

    if (status === 'past') {
      headerMap = [
        { uid: 'name', display: 'Name', size: '30%' },
        { uid: 'startDateTime', display: 'Start Date & Time', size: '25%' },
        { uid: 'endDateTime', display: 'End Date & Time', size: '25%' },
        {
          uid: 'actions', display: 'Actions', size: '20%', actions: ['details', 'delete'],
        },
      ];
    }

    const tableTitle = status === 'live' ? 'Live Stream Event Schedule' : 'Past Live Stream Events';
    const noDataLabel = status === 'live' ? 'No live stream events scheduled yet.' : 'No past live stream events found.';
    const filteredData = organizationLivestreams?.filter(({ status: streamStatus }) => (status === 'live' ? streamStatus !== 'finished' : streamStatus === 'finished'));

    return (
      <Box pad={{ right: small ? '0' : '3rem', top: status === 'live' ? '0' : '2rem' }}>
        <LivestreamsTablePartial
          small={small}
          loading={loading || submitLoading}
          tableTitle={tableTitle}
          noDataLabel={noDataLabel}
          eventStatus={status}
          buttonHighlight={authPagesConfig.buttonHighlight}
          textColor={primaryText}
          highlightColor={secondaryText}
          dividerColor="#394658"
          containerBg={containerBg}
          data={filteredData}
          headerMap={headerMap}
          statusDisplayMap={cyclopsStatusDisplayMap}
          handleEdit={handleEditLivestream}
          handleDelete={handleDeleteLivestream}
          handleStartOrStop={handleStartOrStopLivestream}
        />
      </Box>
    );
  };

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box
        flex
        background={primaryDarkBg}
        pad={{ horizontal: small ? '2rem' : '3rem', bottom: small ? '2rem' : '3rem', top: small ? '3rem' : '4rem' }}
        direction="column"
        gap="1rem"
      >
        <Box direction="column" gap="1rem">
          <Box width="100%" direction="row" justify="between" align="center" pad={{ right: small ? '0' : '3rem' }} gap="2rem">
            <StyledCyclopsHeading noMargin level={2} color={primaryText}>
              {pageTitle}
            </StyledCyclopsHeading>
            {!demoOnly && (
              <AppButton
                overrideHover
                level="dynamicLarge"
                color="white"
                width="9rem"
                bgColor={authPagesConfig.buttonHighlight}
                onClick={() => {
                  setIsEditing(false);
                  setActiveEventId(null);
                  handleResetForm();
                  setShowLivestreamForm(!showLivestreamForm);
                }}
                fontWeight={600}
                label={buttonTitle}
              />
            )}
          </Box>
          {status === 'live' && !showLivestreamForm && (
            <Text color={secondaryText} size="1rem" weight={500}>
              {formattedDate}
            </Text>
          )}
        </Box>
        {renderPageContent(showLivestreamForm)}
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    activeOrganization: state.organizations.activeCompanyOrganization,
    organizationLivestreams: state.cyclops.organizationLivestreams,
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchLivestreams: initiateCyclopsLivestreamsRequest,
    clearLivestreams: clearCyclopsLivestreamsRequest,
    submitNewLivestream: submitNewCyclopsLivestreamRequest,
    editLivestream: editCyclopsLivestreamRequest,
    deleteLivestream: deleteCyclopsLivestreamRequest,
    startOrStopLivestream: startOrStopCyclopsLivestreamRequest,
  }, dispatch);
}

CyclopsEventsListPage.propTypes = {
  small: PropTypes.bool.isRequired,
  demoOnly: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
    cyclopsStatusDisplayMap: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyDemoData: PropTypes.objectOf(PropTypes.any),
  organizationLivestreams: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    maxVideoHeight: PropTypes.string,
    maxVideoWidth: PropTypes.string,
    framesPerSecondNumerator: PropTypes.number,
    framesPerSecondDenominator: PropTypes.number,
    contentOwnerId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    encoderOutboundProtocol: PropTypes.string.isRequired,
    urlOrCidrs: PropTypes.string,
    currentOriginManifestPath: PropTypes.string.isRequired,
    currentHostVideoBaseFolder: PropTypes.string.isRequired,
    clientMediaId: PropTypes.string,
    protocolConfig: PropTypes.object,
    endDateTime: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDateTime: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    expectedViewership: PropTypes.number.isRequired,
    backupOriginUrl: PropTypes.string,
  })),
  activeOrganization: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  fetchLivestreams: PropTypes.func.isRequired,
  clearLivestreams: PropTypes.func.isRequired,
  submitNewLivestream: PropTypes.func.isRequired,
  editLivestream: PropTypes.func.isRequired,
  deleteLivestream: PropTypes.func.isRequired,
  startOrStopLivestream: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withProductAuth(CyclopsEventsListPage),
);
