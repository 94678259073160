import React from 'react';
import styled, { css } from 'styled-components';

import {
  Box, Text, Accordion, Button,
} from 'grommet';

import { DropButton } from '@Components/Control';
import AppAnchor, { StyledAnchor } from '@Components/Control/AppAnchor';

import style from './Header.module.scss';

const labelAnchorStyle = css`
  @media(min-width: 48.0625em) and (max-width: 56.25em) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (max-width: 48em) {
    padding: 1rem 1rem;
    font-size: 17px;
  }
`;

const menuStyle = css`
  && {
    font-size: 1.15rem;
    font-weight: 600;
    padding: 1.5rem 1.25rem;
    line-height: 1.25;
    letter-spacing: normal;
    ${labelAnchorStyle}
  }
`;

const StyledMenuAnchor = styled((props) => <AppAnchor {...props} />)`
  ${menuStyle}
`;

const StyledProfileAnchor = styled((props) => <AppAnchor {...props} />)`
  padding: 0.35rem 1.5rem 0.35rem 0;
  line-height: 1.25;
`;

const StyledHomeAnchor = styled((props) => <AppAnchor {...props} />)`
  && { padding: 0; }
`;

const StyledDropDownSubtitle = styled(Text)`
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: normal;
  text-transform: none;
`;

const StyledDropDownTitle = styled.span`
  text-transform: none;
`;

const StyledMenuDrop = styled(Box)`
  &:last-child > .${style.profileAnchor} {
    padding-right: 0;
  }
`;

const StyledProfileDropDownAnchor = styled(StyledMenuAnchor)`
  && {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 6px;
    color: ${({ primaryText }) => primaryText};

    .loginHighlight {
      background-image: ${({ iconSrc }) => `url(${iconSrc})`};
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:hover, &:hover * {
      color: ${({ highlightText }) => highlightText};
      background: ${({ hoverColor }) => hoverColor};

      .loginHighlight {
        background-image: ${({ iconHoverSrc }) => `url(${iconHoverSrc})`};
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
`;

const StyledMenuDropDownAnchor = styled(StyledMenuAnchor)`
  && {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: ${({ primaryText }) => primaryText};

    &:first-of-type {
      margin-top: 0.5rem;
      border-radius: 0.2rem 0.2rem 0 0;
    }

    &:last-of-type {
      margin-bottom: 0.5rem;
      border-radius: 0 0 0.2rem 0.2rem;
    }

    &:hover, &:hover * {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
`;

const StyledMenuDropDown = styled(StyledMenuAnchor)`
  && {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: ${({ primaryText }) => primaryText};

    &:first-of-type {
      margin-top: 0.5rem;
      border-radius: 0.2rem 0.2rem 0 0;
    }

    &:last-of-type {
      margin-bottom: 0.5rem;
      border-radius: 0 0 0.2rem 0.2rem;
    }

    &:hover, &:hover * {
      color: ${({ hoverColor }) => hoverColor};
    }

    &${StyledAnchor} {
      text-decoration: none;

      ${StyledDropDownTitle} { text-decoration: underline; }
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  padding: 0 1.2rem 0 1.2rem;

  & .${style.accordionItem}${StyledAnchor} {
    ${StyledDropDownTitle} { text-decoration: underline; }
  }

  div { border: none; }

  svg {
    width: 28px;
    height: 28px;
    fill: ${({ iconColor }) => iconColor};
    stroke: ${({ iconColor }) => iconColor};
  }

  button {
    border-radius: 5px;
  }

  button[aria-expanded="true"] {
    background: ${({ highlightColor }) => highlightColor};
  }
`;

const StyledProfile = styled(Box)`
  cursor: pointer;
  padding: 0;

  span, button, a {
    font-size: 1rem;
  }

  h4 { font-weight: 600 };

  @media (max-width: 48em) {
    span, button, a, h4 {
      font-size: 1.25rem;
    }
  }
`;

const StyledLogin = styled((props) => <AppAnchor {...props} />)`
  && {
    margin: 0.75rem;
    margin-right: 0;

    &:hover { color: #3C417F; }

    @media(min-width: 48.0625em) and (max-width: 56.25em) {
      margin-left: 0.6vw;
    }

    @media (max-width: 48em) {
      margin: 1rem 0;

      > button { width: 100%; }
    }
  }
`;

const StyledNavbar = styled(Box)`
  &:not(.${style.navbarNotFixed}) {
    position: fixed;
    z-index: 5;
    top: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    transition: all 0.2s ease;
  }
`;

const StyledAnchorButton = styled((props) => <AppAnchor {...props} />)`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 10px;
  min-width: 6.5rem;
  padding: 0.65rem;

  &:hover, &:hover * {
    background-color: ${({ buttonHoverColor }) => buttonHoverColor};
    text-decoration: none;
    color: ${({ highlightText }) => highlightText};
  }
`;

const StyledMenuControlsContainer = styled(Box)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const StyledMenuDropdownSelect = styled((props) => <DropButton {...props} />)`
  width: 100%;
  border-radius: 0.35rem;
  position: relative;

  > button {
    background: ${({ bgColor }) => bgColor ?? 'white'};
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 0.35rem;
    width: 100%;

    > div {
      border-radius: 0.35rem;
      width: 100%;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
`;

const StyledSelectMenuButton = styled(Button)`
  padding: 0.75rem;
  border-radius: 6px;
  background: ${({ bgColor }) => bgColor ?? 'white'};

  span {
    color: ${({ primaryText }) => primaryText};
  }

  &:hover {
    background: ${({ highlightBg }) => highlightBg};

    span {
      color: ${({ highlightText }) => highlightText};
    }
  }
`;

export {
  StyledNavbar,
  StyledLogin,
  StyledProfile,
  StyledHomeAnchor,
  StyledMenuAnchor,
  StyledMenuDrop,
  StyledMenuDropDown,
  StyledMenuDropDownAnchor,
  StyledDropDownTitle,
  StyledDropDownSubtitle,
  StyledAccordion,
  StyledAnchorButton,
  StyledMenuControlsContainer,
  StyledMenuDropdownSelect,
  StyledSelectMenuButton,
  StyledProfileAnchor,
  StyledProfileDropDownAnchor,
};
