import {
  COMPANY_SETTINGS_LOADED,
  COMPANY_SETTINGS_CLEAR,
  COMPANY_SINGLE_SETTING_LOADED,
  COMPANY_SINGLE_SETTING_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  companySettings: null,
  companySettingData: null,
};

const companySettings = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_SETTINGS_LOADED:
      return {
        ...state,
        companySettings: action.payload.settings,
      };

    case COMPANY_SETTINGS_CLEAR:
      return {
        ...state,
        companySettings: null,
      };

    case COMPANY_SINGLE_SETTING_LOADED:
      return {
        ...state,
        companySettingData: action.payload.setting,
      };

    case COMPANY_SINGLE_SETTING_CLEAR:
      return {
        ...state,
        companySettingData: null,
      };

    default:
      return state;
  }
};

export default companySettings;
