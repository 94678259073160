import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import Case from 'case';

import { Box } from 'grommet';

import { AppButton } from '@Components/Control';
import {
  StyledFormSelect,
  StyledFormSelectMenu,
} from './StyledProductFormComponent';

const SelectMenu = ({
  options = null,
  simpleOptions = null,
  onClick,
  selectButtonBg,
  highlightText,
  containerBg,
  keepLabel = false,
  ...rest
}) => {
  if (simpleOptions && !options) {
    return (
      <Box round="0.35rem" pad="12px" background={containerBg}>
        {simpleOptions.map((val) => (
          <StyledFormSelectMenu
            plain
            key={uniqid()}
            label={keepLabel ? val : Case.capital(val)}
            onClick={() => onClick(val)}
            hoverIndicator={selectButtonBg}
            highlightText={highlightText}
            {...rest}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box round="0.35rem" pad="12px" background={containerBg}>
      {options.map(({ name }) => (
        <StyledFormSelectMenu
          plain
          key={uniqid()}
          label={name}
          onClick={() => onClick(name)}
          hoverIndicator={selectButtonBg}
          highlightText={highlightText}
          {...rest}
        />
      ))}
    </Box>
  );
};

SelectMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired),
  simpleOptions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired),
  onClick: PropTypes.func.isRequired,
  selectButtonBg: PropTypes.string.isRequired,
  highlightText: PropTypes.string.isRequired,
  containerBg: PropTypes.string.isRequired,
  keepLabel: PropTypes.bool,
};

const DropdownSelect = ({
  options = null,
  simpleOptions = null,
  authPagesConfig: {
    selectButtonBg,
    primaryText,
    secondaryText,
    hintText,
    buttonHighlight,
    focusHighlight,
    darkModeColors,
  },
  handleFormValues,
  selectable = null,
  autoSelect = false,
  darkmode = false,
  keepLabel = false,
  label = null,
  value = null,
  ...rest
}) => {
  React.useEffect(() => {
    if ((value === null) && autoSelect && simpleOptions && simpleOptions.length > 0) {
      handleFormValues(simpleOptions[0]);
    } else if (value === null && autoSelect) {
      handleFormValues(false);
    }
  }, []);

  let displayLabel = label;
  const selectOptions = selectable ? simpleOptions : [false, true];

  if (selectable && value && !label && !keepLabel) {
    displayLabel = Case.capital(value);
  }

  if (selectable && value && !label && keepLabel) displayLabel = value;

  if (!selectable && (value || value === false) && !label) {
    displayLabel = value ? 'True' : 'False';
  }

  let labelColor = displayLabel ? primaryText : hintText;

  if (darkmode) {
    labelColor = displayLabel ? darkModeColors.primaryText : hintText;
  }

  return (
    <StyledFormSelect
      noShadow
      formIcons
      bgColor={darkmode ? darkModeColors.primaryDarkBg : 'white'}
      borderColor={darkmode ? '#394658' : '#D0D0D0'}
      highlight={darkmode ? darkModeColors.secondaryText : focusHighlight}
      withCloseDropContent={(onClose) => (
        <SelectMenu
          selectButtonBg={darkmode ? darkModeColors.menuButtonHighlight : selectButtonBg}
          highlightText={darkmode ? darkModeColors.primaryText : buttonHighlight}
          containerBg={darkmode ? darkModeColors.containerBg : 'white'}
          options={options}
          simpleOptions={selectOptions}
          keepLabel={keepLabel}
          onClick={(val) => {
            handleFormValues(val);
            onClose();
          }}
          {...rest}
        />
      )}
      {...rest}
    >
      {(Icon) => (
        <AppButton
          fill
          dropDown
          textSize="1rem"
          color={labelColor}
          label={displayLabel || 'Select an option'}
          icon={<Icon color={darkmode ? darkModeColors.primaryText : secondaryText} size="1.25rem" />}
          {...rest}
        />
      )}
    </StyledFormSelect>
  );
};

DropdownSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired),
  simpleOptions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired),
  handleFormValues: PropTypes.func.isRequired,
  authPagesConfig: PropTypes.shape({
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  selectable: PropTypes.bool,
  autoSelect: PropTypes.bool,
  darkmode: PropTypes.bool,
  keepLabel: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default DropdownSelect;
