import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Button } from 'grommet';

const buttonProps = {
  normal: {
    width: '7.8125rem',
    height: '2.8125rem',
    fontSize: '1rem',
    lineHeight: 1.25,
  },
  small: {
    width: '6.95rem',
    height: '2.325rem',
    fontSize: '0.875rem',
    lineHeight: 1.28,
  },
  xsmall: {
    borderRadius: '0.35rem',
    width: '5.55rem',
    height: '1.7rem',
    fontSize: '0.75rem',
    lineHeight: 1.29,
  },
  large: {
    width: '11.5rem',
    height: '3.25rem',
    fontSize: '1.1rem',
    lineHeight: 1.30,
  },
  xlarge: {
    borderRadius: '0.75rem',
    width: '14rem',
    height: '3.25rem',
    fontSize: '1.1rem',
    lineHeight: 1.30,
  },
  newSearch: {
    borderRadius: '0.25rem',
    width: '6rem',
    height: '2.325rem',
    fontSize: '0.875rem',
    lineHeight: 1.28,
  },
  requestFeature: {
    borderRadius: '0.313rem',
    width: '5rem',
    height: '1.875rem',
    fontSize: '0.75rem',
    lineHeight: 1.29,
  },
  listRequestNew: {
    borderRadius: '0.25rem',
    width: '8.15rem',
    height: '2.5rem',
    fontSize: '0.875rem',
    lineHeight: 1.28,
  },
  authSubmit: {
    borderRadius: '5px',
    width: '100%',
    height: '3.15rem',
    fontSize: '1.1rem',
    lineHeight: 1.30,
  },
  dynamicSmall: {
    borderRadius: '5px',
    width: '6.25rem',
    height: '2rem',
    fontSize: '1rem',
    lineHeight: 1.25,
  },
  dynamicLarge: {
    borderRadius: '15px',
    width: '9.25rem',
    height: '3.15rem',
    fontSize: '1.1rem',
    lineHeight: 1.30,
  },
  mobileMenu: {
    borderRadius: '0',
    width: '100%',
    height: '70px',
    fontSize: '20px',
    lineHeight: 1.30,
  },
  tableControls: {
    borderRadius: '5px',
    width: 'auto',
    height: 'auto',
    padding: '0.3rem 0.5rem',
    fontSize: '1rem',
    lineHeight: 1.25,
  },
};

const StyledButton = styled(Button)`
  display: flex;
  border-radius: ${({ dropDown, level }) => (dropDown ? '0' : (buttonProps[level]?.borderRadius || '0.4375rem'))};
  padding: ${({ level }) => buttonProps[level]?.padding || 0};
  width: ${({ dropDown, width, level }) => (dropDown ? 'auto' : (width || buttonProps[level]?.width))};
  height: ${({ dropDown, height, level }) => (dropDown ? 'auto' : (height || buttonProps[level]?.height))};
  background: ${({ bgColor }) => bgColor || null};
  color: ${({ textColor }) => textColor || null};
  border-width: ${({ dropDown }) => (dropDown ? '0' : '1px')};
  font-size: ${({ textSize, level }) => textSize || buttonProps[level]?.fontSize};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  line-height: ${({ lineHeight, level }) => lineHeight || buttonProps[level]?.lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || 'normal'};
  text-decoration: ${({ decoration }) => decoration || 'inherit'};
  align-items: center;
  justify-content: ${({ justify }) => justify || 'center'};
  ${({ justify }) => justify && justify === 'start' && css`
    padding-left: 30px;

    > div {
      width: 100%;
    }
  `}
  ${({ overrideHover, bgColor }) => overrideHover && bgColor && css`
    background: linear-gradient(#0000, rgb(0 0 0/40%)) top/100% 800%;
    background-color: ${bgColor};
    border: none;
    transition: all 0.25s ease-in-out;
  `}

  ${({ extraWide }) => extraWide && css`
    width: auto;
    padding: 0 0.85rem;
  `}

  &:hover {
    box-shadow: none;
    ${({ overrideHover, disabled }) => overrideHover && !disabled && ('background-position: bottom;')}
    ${({ dropDown, overrideHover }) => !dropDown && !overrideHover && css`
      color: ${({ color, hoverColor }) => (color === 'white' ? (hoverColor || 'var(--primary-text)') : 'white')};
      background: ${({ color }) => color || 'var(--primary-text)'};

      svg {
        stroke: ${({ color, hoverColor }) => (color === 'white' ? (hoverColor || 'var(--primary-text)') : 'white')};
      }
    `}
  }

  &:disabled {
    cursor: default;
    background-color: var(--light-grey);
    color: #a2a2a2;
    border-color: #dcdedf;
    opacity: 1;

    svg {
      stroke: #a2a2a2;
    }
  }
`;

const AppButton = ({
  level = null,
  icon = null,
  dropDown = false,
  ...rest
}) => (
  <StyledButton
    level={level}
    reverse={rest.overrideReverse ? false : dropDown}
    dropDown={dropDown}
    icon={icon}
    gap={icon ? '0.375rem' : null}
    {...rest}
  />
);

AppButton.propTypes = {
  level: PropTypes.oneOf([
    'normal',
    'small',
    'xsmall',
    'large',
    'xlarge',
    'newSearch',
    'requestFeature',
    'listRequestNew',
    'authSubmit',
    'dynamicLarge',
    'dynamicSmall',
    'mobileMenu',
    'tableControls',
  ]),
  icon: PropTypes.node,
  dropDown: PropTypes.bool,
};

export default AppButton;
