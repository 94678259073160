import React from 'react';
import PropTypes from 'prop-types';
import {
  format, subDays, parseISO,
} from 'date-fns';
import { UTCDate } from '@date-fns/utc';

import {
  Box, Layer, Text,
} from 'grommet';

import { SingleDateCalendar } from '@Components/Control/Calendar';
import { localAsset } from '@Helpers/asset';
import {
  StyledModalActivateButton,
} from './StyledProductFormComponent';
import { StyledSVG } from '../StyledSharedComponents';


const CalendarInput = ({
  small,
  handleFormValues,
  value = undefined,
  searchTypeKey = null,
  minStartDate = null,
  today,
  useNextDay = false,
  authPagesConfig,
  inputConfig = null,
  timeSelectOverride = false,
  darkmode = false,
  showCloseButton = false,
  ...rest
}) => {
  const [showCalendar, setShowCalendar] = React.useState(false);
  const dateFormat = value && value.includes('T') ? "M/d/yyyy HH':'mm" : 'M/d/yyyy';
  const yesterday = format(subDays(new UTCDate(today), 1), 'yyyy-MM-dd');
  const useStartDate = useNextDay ? format(new UTCDate(today), 'yyyy-MM-dd') : yesterday;
  let withTimeSelect = inputConfig && inputConfig.format && searchTypeKey
    && inputConfig.format[searchTypeKey]
    && (inputConfig.format[searchTypeKey].optionalTime === true);

  if (timeSelectOverride) withTimeSelect = true;

  const {
    primaryText,
    secondaryText,
    hintText,
    focusHighlight,
    darkModeColors,
  } = authPagesConfig;

  const usePrimaryText = darkmode ? darkModeColors.primaryText : primaryText;

  return (
    <Box>
      <StyledModalActivateButton
        plain
        modalOpen={showCalendar}
        highlight={darkmode ? darkModeColors.secondaryText : focusHighlight}
        borderColor={darkmode ? '#394658' : '#D0D0D0'}
        textSize="1rem"
        onClick={() => setShowCalendar(true)}
        label={(
          <Box wrap={false} direction="row" justify="between" align="center" pad={{ right: '0.15rem' }}>
            <Text size="0.8rem" color={value ? usePrimaryText : hintText}>
              {value ? format(parseISO(value), dateFormat) : 'Select a date'}
            </Text>
            <StyledSVG
              src={localAsset('images/calendar-icon.svg')}
              height="18px"
              width="18px"
              style={{ paddingTop: '0.15rem' }}
              $fillColor={darkmode ? 'white' : secondaryText}
            />
          </Box>
        )}
      />
      {showCalendar && (
        <Layer
          responsive={false}
          animate={false}
          onEsc={() => setShowCalendar(false)}
          onClickOutside={() => setShowCalendar(false)}
        >
          <SingleDateCalendar
            darkmode={darkmode}
            className="authCalendar"
            round="0.35rem"
            pad={{ top: '2rem', bottom: '0', horizontal: '0.5rem' }}
            background="white"
            setDate={(val) => handleFormValues(val)}
            handleClose={() => setShowCalendar(false)}
            minStartDate={minStartDate || useStartDate}
            date={value}
            calendarFormat={withTimeSelect ? 'datetime' : null}
            useAltTimeSelect={withTimeSelect}
            authPagesConfig={authPagesConfig}
            showCloseButton={showCloseButton}
            {...rest}
          />
        </Layer>
      )}
    </Box>
  );
};

CalendarInput.propTypes = {
  small: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  inputConfig: PropTypes.shape({
    format: PropTypes.shape({
      sports: PropTypes.shape({
        optionalTime: PropTypes.bool,
      }),
    }),
  }),
  searchTypeKey: PropTypes.string,
  handleFormValues: PropTypes.func.isRequired,
  minStartDate: PropTypes.string,
  today: PropTypes.instanceOf(Date).isRequired,
  useNextDay: PropTypes.bool,
  value: PropTypes.string,
  timeSelectOverride: PropTypes.bool,
  darkmode: PropTypes.bool,
  showCloseButton: PropTypes.bool,
};

export default CalendarInput;
