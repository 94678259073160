import {
  PRODUCT_LIST_SEARCHES_LOADED,
  PRODUCT_LIST_SEARCHES_CLEAR,
  PRODUCT_LIST_SEARCHES_ADDED_LOADED,
  PRODUCT_LIST_SEARCHES_ADDED_CLEAR,
  PRODUCT_LIST_SEARCHES_META_LOADED,
  PRODUCT_LIST_SEARCHES_META_CLEAR,
  PRODUCT_LIST_SEARCHES_STATS_LOADED,
  PRODUCT_LIST_SEARCHES_STATS_CLEAR,
} from '../actionTypes';

import * as api from '../apiHelper';
import { arriveNotice } from '../noticeActions';
import { setFetchLoaderLoading, setFetchLoaderReset } from '../fetchLoaderActions';
import { setFaviconLoading, setFaviconDefault } from '../faviconActions';

// Reducer corresponding actions
function listProductSearchesClear() {
  return { type: PRODUCT_LIST_SEARCHES_CLEAR };
}

function listProductSearchesLoaded(searches) {
  return {
    type: PRODUCT_LIST_SEARCHES_LOADED,
    payload: { searches },
  };
}

function listProductSearchesAddedClear() {
  return { type: PRODUCT_LIST_SEARCHES_ADDED_CLEAR };
}

function listProductSearchesAddedLoaded(addedSearch) {
  return {
    type: PRODUCT_LIST_SEARCHES_ADDED_LOADED,
    payload: { addedSearch },
  };
}

function listProductSearchesMetaClear() {
  return { type: PRODUCT_LIST_SEARCHES_META_CLEAR };
}

function listProductSearchesMetaLoaded(meta) {
  return {
    type: PRODUCT_LIST_SEARCHES_META_LOADED,
    payload: { meta },
  };
}

function listProductSearchesStatsClear() {
  return { type: PRODUCT_LIST_SEARCHES_STATS_CLEAR };
}

function listProductSearchesStatsLoaded(stats) {
  return {
    type: PRODUCT_LIST_SEARCHES_STATS_LOADED,
    payload: { stats },
  };
}

// API
async function getProductListSearchesApi(args, token) {
  const response = await api.nestedParamsGet('searches', args, token, 'v3');
  return response.json();
}

async function getProductListSearchesAddedApi(args, token) {
  const response = await api.get('searches', args, token, 'v3');
  return response.json();
}

async function getProductListSearchesMetaApi(args, token) {
  const response = await api.get('searches/meta', args, token, 'v3');
  return response.json();
}

async function getProductListSearchesStatsApi(args, token) {
  const response = await api.get('searches/stats', args, token, 'v3');
  return response.json();
}

// helper
async function fetchProductListSearches(dispatch, args, token) {
  try {
    const response = await getProductListSearchesApi(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(listProductSearchesLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchProductListSearchesAdded(dispatch, args, token) {
  try {
    const response = await getProductListSearchesAddedApi(args, token);

    dispatch(listProductSearchesAddedLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchProductListSearchesMeta(dispatch, args, token) {
  try {
    const response = await getProductListSearchesMetaApi(args, token);

    dispatch(setFaviconDefault());
    dispatch(listProductSearchesMetaLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchProductListSearchesStats(dispatch, args, token) {
  try {
    const response = await getProductListSearchesStatsApi(args, token);

    dispatch(setFaviconDefault());
    dispatch(listProductSearchesStatsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
export function clearProductListSearchesRequest() {
  return (dispatch) => {
    dispatch(listProductSearchesClear());
  };
}

export function initiateProductListSearchesRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token }, favicon: { faviconStatus }, fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchProductListSearches(dispatch, args, token);
  };
}

export function clearProductListSearchesAddedRequest() {
  return (dispatch) => {
    dispatch(listProductSearchesAddedClear());
  };
}

export function initiateProductListSearchesAddedRequest() {
  return async (dispatch, getState) => {
    const { auth: { token } } = getState();
    const args = { scope: 'added' };

    await fetchProductListSearchesAdded(dispatch, args, token);
  };
}

export function clearProductListSearchesMetaRequest() {
  return (dispatch) => {
    dispatch(listProductSearchesMetaClear());
  };
}

export function initiateProductListSearchesMetaRequest(args) {
  return async (dispatch, getState) => {
    const { auth: { token }, favicon: { faviconStatus } } = getState();

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchProductListSearchesMeta(dispatch, args, token);
  };
}

export function clearProductListSearchesStatsRequest() {
  return (dispatch) => {
    dispatch(listProductSearchesStatsClear());
  };
}

export function initiateProductListSearchesStatsRequest(args) {
  return async (dispatch, getState) => {
    const { auth: { token }, favicon: { faviconStatus } } = getState();

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchProductListSearchesStats(dispatch, args, token);
  };
}
