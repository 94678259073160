import {
  PRODUCT_LIST_SEARCHES_LOADED,
  PRODUCT_LIST_SEARCHES_CLEAR,
  PRODUCT_LIST_SEARCHES_ADDED_LOADED,
  PRODUCT_LIST_SEARCHES_ADDED_CLEAR,
  PRODUCT_LIST_SEARCHES_META_LOADED,
  PRODUCT_LIST_SEARCHES_META_CLEAR,
  PRODUCT_LIST_SEARCHES_STATS_LOADED,
  PRODUCT_LIST_SEARCHES_STATS_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  productListSearches: null,
  productListSearchesAdded: null,
  productListSearchesMeta: null,
  productListSearchesStats: null,
};

const productList = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_LIST_SEARCHES_LOADED:
      return { ...state, productListSearches: action.payload.searches };

    case PRODUCT_LIST_SEARCHES_CLEAR:
      return initialState;

    case PRODUCT_LIST_SEARCHES_ADDED_LOADED:
      return { ...state, productListSearchesAdded: action.payload.addedSearch };

    case PRODUCT_LIST_SEARCHES_ADDED_CLEAR:
      return initialState;

    case PRODUCT_LIST_SEARCHES_META_LOADED:
      return { ...state, productListSearchesMeta: action.payload.meta };

    case PRODUCT_LIST_SEARCHES_META_CLEAR:
      return initialState;

    case PRODUCT_LIST_SEARCHES_STATS_LOADED:
      return { ...state, productListSearchesStats: action.payload.stats };

    case PRODUCT_LIST_SEARCHES_STATS_CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default productList;
