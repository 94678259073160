import {
  COMPANY_SETTINGS_LOADED,
  COMPANY_SETTINGS_CLEAR,
  COMPANY_SINGLE_SETTING_LOADED,
  COMPANY_SINGLE_SETTING_CLEAR,
} from '../actionTypes';

import * as api from '../apiHelper';
import { initiateRedirectRequest } from '../redirectActions';
import {
  setFetchLoaderLoading,
  setFetchLoaderReset,
  setSubmissionLoaderReset,
  setSubmissionLoaderLoading,
} from '../fetchLoaderActions';
import { setFaviconLoading, setFaviconDefault } from '../faviconActions';
import { arriveNotice } from '../noticeActions';

// Reducer corresponding actions
function companySettingsLoaded(settings) {
  return {
    type: COMPANY_SETTINGS_LOADED,
    payload: { settings },
  };
}

function companySettingsClear() {
  return { type: COMPANY_SETTINGS_CLEAR };
}

function companySingleSettingLoaded(setting) {
  return {
    type: COMPANY_SINGLE_SETTING_LOADED,
    payload: { setting },
  };
}

function companySingleSettingClear() {
  return { type: COMPANY_SINGLE_SETTING_CLEAR };
}

// API
async function getCompanySettings(args, token) {
  const response = await api.get('settings', args, token, 'v3');
  return response.json();
}

async function getSingleCompanySetting(name, args, token) {
  const response = await api.get(`settings/${name}`, args, token, 'v3');
  return response.json();
}

async function updateCompanyDomainApi(name, body, token) {
  const response = await api.put(`settings/${name}`, body, token, 'v3');
  return response.json();
}

// Helper
async function fetchCompanySettings(dispatch, args, token) {
  try {
    const response = await getCompanySettings(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(companySettingsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchSingleCompanySetting(dispatch, name, args, token) {
  try {
    const response = await getSingleCompanySetting(name, args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(companySingleSettingLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function updateCompanyDomain(dispatch, name, body, token, redirectPath, afterAction) {
  try {
    const { notice } = await updateCompanyDomainApi(name, body, token);

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
    afterAction();
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
export function clearCompanySettingsRequest() {
  return (dispatch) => {
    dispatch(companySettingsClear());
  };
}

export function initiateCompanySettingsRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token }, favicon: { faviconStatus }, fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchCompanySettings(dispatch, args, token);
  };
}

export function clearSingleCompanySettingRequest() {
  return (dispatch) => {
    dispatch(companySingleSettingClear());
  };
}

export function initiateSingleCompanySettingRequest(name, args) {
  return async (dispatch, getState) => {
    const {
      auth: { token }, favicon: { faviconStatus }, fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchSingleCompanySetting(dispatch, name, args, token);
  };
}

export function updateCompanyDomainRequest(name, body, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await updateCompanyDomain(dispatch, name, body, token, redirectPath, afterAction);
  };
}
